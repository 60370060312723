//import projectReducer from './reducers/generic/project';
import { configureStore } from '@reduxjs/toolkit';
import projectReducer from './reducers/generic/project';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import user from "./reducers/generic/user"
import loader from './reducers/generic/loader';

export const store = configureStore({
    reducer: {
        project: projectReducer
    }
});

const userPersistConfig = {
    key: 'admin-app',
    storage: storage,
    blacklist: ['loader'],
};

export default persistCombineReducers(userPersistConfig, {
    user,
    loader
});