import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { getApiDomain } from '../auth_config';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

export const link = apiKey => {
    let headers = {};
    if (apiKey) {
        headers = {
            'x-api-key': apiKey,
            'x-parent-domain': document.referrer || document.location.origin
        };
    }
    return split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        new GraphQLWsLink(
            createClient({
                url:
                    getApiDomain()
                        .replace('https', 'wss')
                        .replace('http', 'ws') + '/graphql',
                shouldRetry: () => true,
                retryWait: async function waitForServerHealthyBeforeRetry() {
                    await new Promise(resolve =>
                        setTimeout(resolve, 500 + Math.random() * 1000)
                    );
                }
            })
        ),
        new HttpLink({
            uri: getApiDomain() + '/graphql',
            headers
        })
    );
};

export const client = new ApolloClient({
    link: link(null),
    cache: new InMemoryCache()
});

export const clientWithAPIKey = apiKey => {
    return new ApolloClient({
        link: link(apiKey),
        cache: new InMemoryCache()
    });
};
