import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    venue: null,
    venues: []
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setVenue: (state, action) => {
            state.venue = action.payload;
        },
        setVenues: (state, action) => {
            state.venues = action.payload;
        }
    }
});
export const {
    setVenue,
    setVenues
} = projectSlice.actions;

export const selectVenue = state => state.project.venue;
export const selectVenues = state => state.project.venues;

export default projectSlice.reducer;
