import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './tailwind.css';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
// import { store } from './redux/store';
import configureStoreProd from "./config/configureStore.prod";

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

const { persistor, store } = configureStoreProd();

root.render(
    <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
