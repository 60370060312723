import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import environment from "../../environment";
import { IoCloseOutline, IoDocumentTextOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import { toast } from "react-toastify";
import FormControl from "../../common/FormControl";
import SelectDropdown from "../../common/SelectDropdown";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import loader from "../../methods/loader";
import DesktopSidebar from "../Home/Sidebars/DesktopSidebar";
import { useDispatch, useSelector } from "react-redux";
import { login_success } from "../../redux/actions/user";

const VenueProfile = () => {
    const user = useSelector((state) => state.user)
    const [form, setform] = useState({
        venue_name: user?.venue_name || "",
        name: user?.name || "",
        email: user?.email || "",
        mobileNo: user?.mobileNo || "",
        image: user?.image || "",
        amenities: user?.amenities || [],
        images: user?.images || [],
        description: user?.description || "",
        location: user?.location || "",
        country: user?.country || "",
        state: user?.state || "",
        city: user?.city || "",
        zipCode: user?.zipCode || "",
        website_link: user?.website_link || "",
        instagram_link: user?.instagram_link || "",
        facebook_link: user?.facebook_link || "",
        tiktok_link: user?.tiktok_link || "",
        youtube_link: user?.youtube_link || "",
        applyAll: user?.applyAll || true,
        time_schedule: user?.time_schedule ?
            user?.time_schedule?.map((item) => {
                return ({ ...item, start_time: new Date(item?.start_time), end_time: new Date(item?.end_time), best_time_to_visit: new Date(item?.best_time_to_visit) })
            })
            : [
                { day: "monday", start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "tuesday", start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "wednesday", start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "thursday", start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "friday", start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "saturday", start_time: "", end_time: "", best_time_to_visit: "" },
                { day: "sunday", start_time: "", end_time: "", best_time_to_visit: "" },
            ],
        foods: user?.foods || [{ item: "", price: "", image: "" }],
        drinks: user?.drinks || [{ item: "", price: "", image: "" }],
        menu_item_format: user?.menu_item_format || "",
        foodImages: user?.foodImages || "",
        company_ein: user?.company_ein || "",
        ein_image: user?.ein_image || "",
    });
    const dispatch = useDispatch()
    const [amenities, setAmenities] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [editAble, setEditAble] = useState(false)

    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const height = pageHeight - (pageWidth < 1024 ? 56 : 0) + 'px';

    const foodFormatOptions = [
        { id: "manual", name: "Manual" },
        { id: "upload", name: "Upload" },
    ]

    // Create an event handler that tracks the height of the window
    useEffect(() => {
        getAmenities()

        const resizeHandler = () => {
            setPageHeight(window.innerHeight);
            setPageWidth(window.innerWidth);
        };
        window.addEventListener('resize', resizeHandler);
        resizeHandler(); // Call it initially to set the initial width
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    const getAmenities = () => {
        ApiClient.get(`amenity/listing?status=active&sortBy=title asc`).then(res => {
            if (res.success) {
                setAmenities(res?.data?.map((item) => {
                    return ({ id: item?._id, name: item?.title })
                }))
            }
        })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!form?.ein_image) return
        let method = "put";
        let url = "users/edit/profile";
        let value = { ...form, id: user?.id || user?._id }
        loader(true)
        ApiClient.allApi(url, value, method).then((res) => {
            if (res.success) {
                dispatch(login_success({ ...user, ...value }))
                toast.success(res?.message)
            }
            loader(false)
        });
    }

    const ImagesUpload = (e, key) => {
        let files = Array.from(e.target.files);
        const acceptedTypes = ['image/jpeg', 'image/png']; // Add more image types if needed
        const filteredFiles = files.filter(file => acceptedTypes.includes(file.type));
        let invalidFiles = files.filter(file => !acceptedTypes.includes(file.type));
        if (invalidFiles.length > 0 && files?.length > 1) {
            toast.error('Some files are not valid format and will be ignored.Only JPG and PNG images are allowed.');
        }
        if (filteredFiles.length !== files.length && files?.length === 1) {
            toast.error('Only JPG and PNG images are allowed.');
        }
        if (filteredFiles?.length === 0) {
            return;
        }
        let images = form?.[key] || []
        loader(true)
        ApiClient.multiImageUpload('upload/multiple-images', filteredFiles, {}, "files").then(res => {
            if (res.success) {
                const data = res?.files?.map((item) => item?.fileName)
                images.push(...data)
                setform({ ...form, [key]: images })
            }
            loader(false)
        })
    }

    const handleImageDelete = (index, key) => {
        const data = [...form?.[key]]
        data.splice(index, 1)
        setform({ ...form, [key]: data })
    }

    const handleLocation = (place) => {
        const addressComponents = place?.address_components;
        const address = {};

        for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            const types = component.types;
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.long_name;
            }
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zipCode = component.long_name;
            }
        }

        address.location = place?.formatted_address
        address.coordinates = {
            type: "Point",
            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
        }
        setform((prev) => ({ ...prev, ...address }))
    }

    const handleTimeSchedule = (date, index, key) => {
        let data = form?.time_schedule
        if (form?.applyAll) {
            data = data.map((item, i) => {
                return { ...item, [key]: date }
            })
        } else {
            data[index][key] = date
        }
        setform((prev) => ({ ...prev, time_schedule: data }))
    }

    const handleApplyAll = (checked) => {
        const data = form?.time_schedule[0]
        const timeSchedule = [
            { day: "monday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "tuesday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "wednesday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "thursday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "friday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "saturday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "sunday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
        ]
        if (checked) {
            setform((prev) => ({ ...prev, applyAll: checked, time_schedule: timeSchedule }))
        } else {
            setform((prev) => ({ ...prev, applyAll: checked }))
        }
    }

    const handleAddMoreInput = (e, index, key, object) => {
        let data = form?.[object]
        data[index][key] = e
        setform((prev) => ({ ...prev, [object]: data }))
    }
    const handleAddMore = (key) => {
        const data = [...form?.[key], { item: "", price: "", image: "" }]
        setform((prev) => ({ ...prev, [key]: data }))
    }
    const handleRemoveAddMore = (index, key) => {
        const data = form?.[key]?.filter((item, i) => i !== index)
        setform((prev) => ({ ...prev, [key]: data }))
    }

    const ImageUpload = (e, index, key, object) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        let data = form?.[object]
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                data[index][key] = res?.fileName
                setform((prev) => ({ ...prev, [object]: data }))
            }
            loader(false)
        })
    }

    const handleMenuImage = (index, key, object) => {
        let data = form?.[object]
        data[index][key] = ""
        setform((prev) => ({ ...prev, [object]: data }))
    }

    const uploadEIN = (e) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only doc and pdf are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/document', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, ein_image: res?.fileName })
            }
            loader(false)
        })
    }

    const venueProfileImageUpload = (e) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, image: res?.fileName })
            }
            loader(false)
        })
    }

    return (
        <>
            <div className="bg-white w-full h-screen flex flex-row " style={{ maxHeight: height }}>
                <DesktopSidebar />
                <div id="mainPage" className="w-full flex h-full overflow-auto lg:h-screen flex-col justify-center p-5">
                    <div className="w-full flex flex-col h-full">
                        <div className="flex justify-between bg-[#005AAB] rounded-md px-5 py-3 mb-3">
                            <h2 className="text-[24px] font-[600] text-white">Profile Page</h2>
                            <div onClick={e => { setEditAble(!editAble) }} type="button" className="text-white bg-[#fff3] font-medium rounded-lg text-sm py-2.5 px-3 text-center cursor-pointer">{editAble ? "Cancel" : "Edit"}</div>
                        </div>
                        <form onSubmit={handleUpdate} autoComplete="one-time-code">
                            <div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Venue Location</h2>
                                    <div className="p-5">

                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="venue_name"
                                                    label="Venue Name"
                                                    placeholder="Enter Venue Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.venue_name}
                                                    onChange={(e) => setform({ ...form, venue_name: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>Location <span className="text-red-600">*</span></label>
                                                <ReactGoogleAutocomplete
                                                    apiKey={environment?.map_api_key}
                                                    onPlaceSelected={(place) => { handleLocation(place) }}
                                                    onChange={e => setform({ ...form, location: e.target.value })}
                                                    value={form?.location}
                                                    types={['(regions)']}
                                                    key="venueLocation"
                                                    placeholder="Enter Location"
                                                    disabled={!editAble}
                                                    required
                                                    className={`bg-white w-full h-10 rounded-lg overflow-hidden px-2 mt-1 border border-[#00000036] ${!editAble && "cursor-not-allowed"}`}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="country"
                                                    label="Country"
                                                    placeholder="Enter country"
                                                    autoComplete="one-time-code"
                                                    value={form?.country}
                                                    onChange={(e) => setform({ ...form, country: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="state"
                                                    label="State"
                                                    placeholder="Enter State"
                                                    autoComplete="one-time-code"
                                                    value={form?.state}
                                                    onChange={(e) => setform({ ...form, state: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="city"
                                                    label="City"
                                                    placeholder="Enter City"
                                                    autoComplete="one-time-code"
                                                    value={form?.city}
                                                    onChange={(e) => setform({ ...form, city: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="number"
                                                    name="zipCode"
                                                    label="Zip Code"
                                                    placeholder="Enter Zip Code"
                                                    autoComplete="one-time-code"
                                                    value={form?.zipCode}
                                                    onChange={(e) => setform({ ...form, zipCode: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    maxlength="6"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Contact Info</h2>
                                    <div className="p-5">
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Enter Name"
                                                    autoComplete="one-time-code"
                                                    value={form?.name}
                                                    onChange={(e) => setform({ ...form, name: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="email"
                                                    label="Email"
                                                    placeholder="Enter Email"
                                                    autoComplete="one-time-code"
                                                    className={"cursor-not-allowed"}
                                                    value={form.email}
                                                    disabled
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-6 mobile_number mb-3">
                                                <FormControl
                                                    type="phone"
                                                    name="mobileNo"
                                                    autoComplete="one-time-code"
                                                    label="Mobile Number"
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    value={form?.mobileNo}
                                                    onChange={(e) => setform({ ...form, mobileNo: e })}
                                                />
                                            </div>
                                            <div className="col-span-12 mb-3">
                                                <label>Profile Image (JPG/PNG)</label>
                                                <div className="flex flex-col rounded-lg gap-6 max-sm:mx-auto">
                                                    {form?.image ? (
                                                        <>
                                                            <div className="flex flex-wrap gap-3 mt-3">
                                                                <div className="relative">
                                                                    <img src={methodModel.userImg(form?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                    {editAble && <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, image: "" })} size={25} />}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <label className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"} flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[250px]`} style={{ gap: '8px' }}>
                                                            <FiPlus />
                                                            <input
                                                                id="dropzone-file"
                                                                type="file"
                                                                className="hidden"
                                                                disabled={!editAble}
                                                                onChange={venueProfileImageUpload}
                                                            />
                                                            Upload Profile Image
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Gallery</h2>
                                    <div className="p-5">
                                        <div className="mb-3">
                                            <label>Venue Images (JPG/PNG)</label>
                                            <div className={`flex flex-col rounded-lg gap-6 max-sm:mx-auto`}>
                                                <label className={`${!editAble ? "cursor-not-allowed" : "cursor-pointer"} flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                                    <FiPlus />
                                                    <input
                                                        id="dropzone-file"
                                                        type="file"
                                                        className="hidden"
                                                        disabled={!editAble}
                                                        multiple
                                                        onChange={e => ImagesUpload(e, "images")}
                                                    />
                                                    Upload Images
                                                </label>
                                                {form?.images?.length > 0 ?
                                                    <span className="flex flex-wrap gap-3">
                                                        {form?.images?.map((item, index) => {
                                                            return <div className="relative" key={index}>
                                                                <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                {editAble && <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, 'images')} size={25} />}
                                                            </div>
                                                        })}
                                                    </span> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <FormControl
                                                type="editor"
                                                name="description"
                                                label="Description"
                                                className={`h-[200px] overflow-auto ${!editAble && "cursor-not-allowed"}`}
                                                disabled={!editAble}
                                                value={form?.description}
                                                onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Time Schedule</h2>
                                    <div className="p-5">
                                        <div className="overflow-auto">
                                            <table className="table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <tr>
                                                    <th className="px-2 py-3 cursor-pointer whitespace-nowrap">
                                                        <label className={`flex items-cneter gap-2 ${!editAble ? "cursor-not-allowed" : "cursor-pointer"}`}>
                                                            <input type="checkbox" disabled={!editAble} onChange={e => handleApplyAll(e.target.checked)} checked={form?.applyAll} className={`h-5 w-5 ${!editAble && "cursor-not-allowed"}`} />Apply for all
                                                        </label>
                                                    </th>
                                                    <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Start Time <span className="text-red-600">*</span></th>
                                                    <th className="px-2 py-3 cursor-pointer whitespace-nowrap">End Time <span className="text-red-600">*</span></th>
                                                    <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Best Time To Visit </th>
                                                </tr>
                                                {form?.time_schedule?.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td className="px-2 py-4 whitespace-nowrap undefined capitalize">{item?.day}</td>
                                                        <td className="px-2 py-4 whitespace-nowrap undefined">
                                                            <DatePicker
                                                                selected={item?.start_time}
                                                                onChange={(date) => handleTimeSchedule(date, index, 'start_time')}
                                                                className={`border border-[#00000036] rounded-md h-8 p-2 ${!editAble || index !== 0 && form?.applyAll ? "cursor-not-allowed" : ""}`}
                                                                dateFormat="hh:mm a"
                                                                placeholderText="Start Time"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Start Time"
                                                                disabled={!editAble || index !== 0 && form?.applyAll}
                                                                required
                                                            />
                                                        </td>
                                                        <td className="px-2 py-4 whitespace-nowrap undefined">
                                                            <DatePicker
                                                                selected={item?.end_time}
                                                                onChange={(date) => handleTimeSchedule(date, index, 'end_time')}
                                                                className={`border border-[#00000036] rounded-md h-8 p-2 ${!editAble || index !== 0 && form?.applyAll ? "cursor-not-allowed" : ""}`}
                                                                dateFormat="hh:mm a"
                                                                placeholderText="End Time"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="End Time"
                                                                disabled={!editAble || index !== 0 && form?.applyAll}
                                                                required
                                                            />
                                                        </td>
                                                        <td className="px-2 py-4 whitespace-nowrap undefined">
                                                            <DatePicker
                                                                selected={item?.best_time_to_visit}
                                                                onChange={(date) => handleTimeSchedule(date, index, 'best_time_to_visit')}
                                                                className={`border border-[#00000036] rounded-md h-8 p-2 ${!editAble || index !== 0 && form?.applyAll ? "cursor-not-allowed" : ""}`}
                                                                dateFormat="hh:mm a"
                                                                placeholderText="Best Time To Visit"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Best Time"
                                                                disabled={!editAble || index !== 0 && form?.applyAll}
                                                            />
                                                        </td>
                                                    </tr>
                                                })}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Social Media</h2>
                                    <div className="p-5">
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="website_link"
                                                    label="Website Link"
                                                    placeholder="Enter Website Link"
                                                    autoComplete="one-time-code"
                                                    value={form?.website_link}
                                                    onChange={(e) => setform({ ...form, website_link: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="instagram_link"
                                                    label="Instagram Link"
                                                    placeholder="Enter Instagram Link"
                                                    autoComplete="one-time-code"
                                                    value={form?.instagram_link}
                                                    onChange={(e) => setform({ ...form, instagram_link: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="facebook_link"
                                                    label="Facebook Link"
                                                    placeholder="Enter Facebook Link"
                                                    autoComplete="one-time-code"
                                                    value={form?.facebook_link}
                                                    onChange={(e) => setform({ ...form, facebook_link: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="tiktok_link"
                                                    label="Tiktok Link"
                                                    placeholder="Enter Tiktok Link"
                                                    autoComplete="one-time-code"
                                                    value={form?.tiktok_link}
                                                    onChange={(e) => setform({ ...form, tiktok_link: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="youtube_link"
                                                    label="Youtube Link"
                                                    placeholder="Enter Youtube Link"
                                                    autoComplete="one-time-code"
                                                    value={form?.youtube_link}
                                                    onChange={(e) => setform({ ...form, youtube_link: e })}
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Menu Items & Amenities</h2>
                                    <div className="p-5">
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                            <div className="mb-3">
                                                <label>Amenities <span className="text-red-600">*</span></label>
                                                <MultiSelectDropdown
                                                    id="statusDropdown"
                                                    placeholder="Select Amenities"
                                                    intialValue={form?.amenities}
                                                    className={`mt-1 capitalize ${!editAble && "cursor-not-allowed"}`}
                                                    result={(e) => setform({ ...form, amenities: e?.value })}
                                                    options={amenities}
                                                    disabled={!editAble}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label>Menu Items Format <span className="text-red-600">*</span></label>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    className="mt-1"
                                                    placeholder="Select Choose Format"
                                                    theme="search"
                                                    intialValue={form?.menu_item_format}
                                                    result={(e) => setform({ ...form, menu_item_format: e?.value })}
                                                    options={foodFormatOptions}
                                                    disabled={!editAble}
                                                    isClearable={false}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        {form?.menu_item_format === "manual" &&
                                            <>
                                                <div className="mb-3">
                                                    <label>Foods</label>
                                                    <div className="grid grid-cols-12 gap-3">
                                                        <div className="col-span-12">
                                                            {form?.foods?.map((itm, index) => {
                                                                return <div key={index} className="flex mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="foods"
                                                                        placeholder="Enter Item"
                                                                        autoComplete="one-time-code"
                                                                        value={itm?.item}
                                                                        onChange={(e) => handleAddMoreInput(e, index, "item", "foods")}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                        required
                                                                    />
                                                                    <FormControl
                                                                        type="number"
                                                                        name="price"
                                                                        placeholder="Enter Price"
                                                                        autoComplete="one-time-code"
                                                                        className={`mx-3 ${!editAble && "cursor-not-allowed"}`}
                                                                        value={itm?.price}
                                                                        onChange={(e) => handleAddMoreInput(e, index, "price", "foods")}
                                                                        disabled={!editAble}
                                                                        maxlength={10}
                                                                        required
                                                                    />
                                                                    <div className="flex flex-col rounded-lg cursor-pointer w-[80%] gap-6 max-sm:mx-auto ml-6">
                                                                        {itm?.image ? (
                                                                            <>
                                                                                <div className="relative flex flex-wrap gap-3 mt-3">
                                                                                    <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                                                    {editAble && <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "foods")} size={25} />}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                                <FiPlus />
                                                                                <input
                                                                                    id="dropzone-file"
                                                                                    type="file"
                                                                                    disabled={!editAble}
                                                                                    className="hidden"
                                                                                    onChange={e => ImageUpload(e, index, "image", "foods")}
                                                                                />
                                                                                Upload Image
                                                                            </label>
                                                                        )}
                                                                    </div>
                                                                    {form?.foods?.length > 1 && editAble &&
                                                                        <div className="ml-3 bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "foods")}><GrSubtractCircle size={20} /></div>
                                                                    }
                                                                </div>
                                                            })}
                                                        </div>
                                                        {editAble &&
                                                            <div className="col-span-12 flex justify-end">
                                                                <div className="bg-[#003b85] p-3 text-white rounded-lg cursor-pointer w-fit ml-3" onClick={e => handleAddMore("foods")}>
                                                                    <IoMdAddCircleOutline size={20} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Drinks</label>
                                                    <div className="grid grid-cols-12 gap-3">
                                                        <div className="col-span-12">
                                                            {form?.drinks?.map((itm, index) => {
                                                                return <div key={index} className="flex mb-3">
                                                                    <FormControl
                                                                        type="text"
                                                                        name="drinks"
                                                                        placeholder="Enter Item"
                                                                        autoComplete="one-time-code"
                                                                        value={itm?.item}
                                                                        onChange={(e) => handleAddMoreInput(e, index, "item", "drinks")}
                                                                        disabled={!editAble}
                                                                        className={!editAble && "cursor-not-allowed"}
                                                                        required
                                                                    />
                                                                    <FormControl
                                                                        type="number"
                                                                        name="price"
                                                                        placeholder="Enter Price"
                                                                        autoComplete="one-time-code"
                                                                        className={`mx-3 ${!editAble && "cursor-not-allowed"}`}
                                                                        disabled={!editAble}
                                                                        value={itm?.price}
                                                                        onChange={(e) => handleAddMoreInput(e, index, "price", "drinks")}
                                                                        maxlength={10}
                                                                        required
                                                                    />
                                                                    <div className="flex flex-col rounded-lg cursor-pointer w-[80%] gap-6 max-sm:mx-auto ml-6">
                                                                        {itm?.image ? (
                                                                            <>
                                                                                <div className="relative flex flex-wrap gap-3 mt-3">
                                                                                    <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                                                    {editAble && <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "drinks")} size={25} />}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                                <FiPlus />
                                                                                <input
                                                                                    id="dropzone-file"
                                                                                    type="file"
                                                                                    className="hidden"
                                                                                    disabled={!editAble}
                                                                                    onChange={e => ImageUpload(e, index, "image", "drinks")}
                                                                                />
                                                                                Upload Image
                                                                            </label>
                                                                        )}
                                                                    </div>
                                                                    {form?.drinks?.length > 1 && editAble &&
                                                                        <div className="ml-3 bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "drinks")}><GrSubtractCircle size={20} /></div>
                                                                    }
                                                                </div>
                                                            })}
                                                        </div>
                                                        {editAble &&
                                                            <div className="col-span-12 flex justify-end">
                                                                <div className="bg-[#003b85] p-3 text-white rounded-lg cursor-pointer w-fit ml-3" onClick={e => handleAddMore("drinks")}>
                                                                    <IoMdAddCircleOutline size={20} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {form?.menu_item_format === "upload" &&
                                            <>
                                                <label>Upload Food Images (JPG/PNG)</label>
                                                <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                                    <label className={`flex items-center justify-center ${!editAble ? "cursor-not-allowed" : "cursor-pointer"} text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px] `} style={{ gap: '8px' }}>
                                                        <FiPlus />
                                                        <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            className="hidden"
                                                            disabled={!editAble}
                                                            multiple
                                                            onChange={e => ImagesUpload(e, 'foodImages')}
                                                        />
                                                        Upload Images
                                                    </label>
                                                    {form?.foodImages?.length > 0 ?
                                                        <span className="flex flex-wrap gap-3">
                                                            {form?.foodImages?.map((item, index) => {
                                                                return <div className="relative" key={index}>
                                                                    <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                                    {editAble && <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, "foodImages")} size={25} />}
                                                                </div>
                                                            })}
                                                        </span> : null
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="shadow-md rounded-md border-[1px] border-[#ededed] mb-4">
                                    <h2 className="text-[18px] font-[500] text-[#005AAB] bg-[#f3f3f3] rounded-t-lg p-3">Documents</h2>
                                    <div className="p-5">
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-6 mb-3">
                                                <FormControl
                                                    type="text"
                                                    name="company_ein"
                                                    label="Company EIN"
                                                    placeholder="Enter Company EIN"
                                                    autoComplete="one-time-code"
                                                    disabled={!editAble}
                                                    className={!editAble && "cursor-not-allowed"}
                                                    value={form?.company_ein}
                                                    onChange={(e) => setform({ ...form, company_ein: e })}
                                                    required
                                                />
                                            </div>
                                            <div className="col-span-12 mb-3">
                                                <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Upload Company EIN <span className="text-red-600">*</span>
                                                </label>
                                                {form?.ein_image ?
                                                    <div className="relative">
                                                        <IoDocumentTextOutline size={20} className="bg-white thumbnail !w-[60px] !h-[60px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                        {editAble && <IoCloseOutline className="absolute -top-2 left-[60px] cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, ein_image: "" })} size={25} />}
                                                    </div>
                                                    :
                                                    <label className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 cursor-pointer">
                                                        <div className="text-center">
                                                            <div className={`flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                <FiPlus />
                                                                <input
                                                                    id="dropzone-file"
                                                                    type="file"
                                                                    className="hidden"
                                                                    multiple
                                                                    onChange={e => uploadEIN(e)}
                                                                />
                                                                Upload EIN
                                                            </div>
                                                        </div>
                                                    </label>
                                                }
                                                {!form?.ein_image && submitted &&
                                                    <span className="text-red-600">Company EIN is required</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right m-4">
                                    <button type="submit" className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form >
                    </div>
                </div>
            </div>
        </>
    )
}

export default VenueProfile