import { useSelector } from "react-redux";

const dummyVenues = [
    {
        _id: '1',
        locationCity: 'Philadelphia',
        locationCitySection: 'Bella Vista',
        locationState: 'Pa',
        business: 'Bar',
        businessName: '12 Steps Down',
        category: 'Bar',
        hostOrVenue: 'Venue',
        website: '12stepsdown',
        instagram: 's'
    },
    {
        _id: '2',
        locationCity: 'West Palm Beach',
        locationCitySection: 'West Palm Beach',
        locationState: 'Fl',
        business: 'Bar',
        businessName: '123 Datura Bar & Kitchen',
        category: 'Food & Drink',
        hostOrVenue: 'Venue',
        website: '123datura',
        instagram: '123datura'
    },
    {
        _id: '3',
        locationCity: 'Dania Beach',
        locationCitySection: 'Dania Beach',
        locationState: 'Fl',
        business: 'Bar',
        businessName: '14 North Dania',
        category: 'Bar',
        hostOrVenue: 'Venue',
        website: '14northdania',
        instagram: 'IG'
    },
    {
        _id: '4',
        locationCity: 'Fort Lauderdale',
        locationCitySection: 'Fort Lauderdale',
        locationState: 'Fl',
        business: 'Venue',
        businessName: '15th Street Fisheries',
        category: 'Restaurant',
        hostOrVenue: 'Venue',
        website: '15th Street Fisheries',
        instagram: ''
    },
    {
        _id: '5',
        locationCity: 'South Florida',
        locationCitySection: 'South Florida',
        locationState: 'Fl',
        business: 'Other',
        businessName: '2 Girls 1 Blunt Podcast',
        category: 'Podcast',
        hostOrVenue: 'Host',
        website: '2girls1bluntpod',
        instagram: '2girls1bluntpod'
    },
    {
        _id: '6',
        locationCity: 'Pompano Beach',
        locationCitySection: 'Pompano Beach',
        locationState: 'Fl',
        business: 'Bar',
        businessName: '26 Degree Brewing Company',
        category: 'Brewery',
        hostOrVenue: 'Venue',
        website: '26brewing',
        instagram: '26brewing'
    },
    {
        _id: '7',
        locationCity: 'Fort Lauderdale',
        locationCitySection: 'Fort Lauderdale',
        locationState: 'Fl',
        business: 'Bar',
        businessName: '27 Bar and lounge',
        category: 'Lounge',
        hostOrVenue: 'Venue',
        website: 'N/A',
        instagram: '27.barlounge'
    },
    {
        _id: '8',
        locationCity: 'Dania Beach',
        locationCitySection: 'Dania Beach',
        locationState: 'Fl',
        business: 'Bar',
        businessName: '3 Son\'s Brewing Co',
        category: 'Brewery',
        hostOrVenue: 'Venue',
        website: '3sonsbrewingco',
        instagram: 'IG'
    },
    {
        _id: '9',
        locationCity: 'New York',
        locationCitySection: 'New York',
        locationState: 'Ny',
        business: 'Venue',
        businessName: '310bowerybar',
        category: 'Bar',
        hostOrVenue: 'Venue',
        website: '',
        instagram: 'https://www.instagram.com/310bowerybar/'
    },
    {
        _id: '10',
        locationCity: 'Fort Lauderdale',
        locationCitySection: 'Fort Lauderdale',
        locationState: 'Fl',
        business: 'Bar',
        businessName: '33rd Street Wine Bar',
        category: 'Bar',
        hostOrVenue: 'Venue',
        website: 'N/A',
        instagram: 'IG'
    }
];

const UserHome = () => {
    const user = useSelector((state) => state.user)

    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
                    <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
                        {dummyVenues.map((venue) => (
                            <div key={venue._id} className="mb-[200px]">
                                <div className="border-b border-gray-200 pb-10">
                                    <h2 className="font-medium text-gray-500">{venue.businessName}</h2>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{venue.locationCity}, {venue.locationState}</p>
                                </div>
                                <dd className="mt-3 text-sm text-gray-500">{venue.locationCitySection}</dd>
                                <dd className="mt-3 text-sm text-gray-500">{venue.website}</dd>
                                <dd className="mt-3 text-sm text-gray-500">{venue.instagram}</dd>
                                <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 mt-4">
                                    <img
                                        alt="Venue"
                                        src="https://tailwindui.com/img/ecommerce-images/product-feature-09-main-detail.jpg"
                                        className="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
                                    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                                        <img
                                            alt="Venue detail"
                                            src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-01.jpg"
                                            className="h-full w-full object-cover object-center"
                                        />
                                    </div>
                                    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                                        <img
                                            alt="Venue detail"
                                            src="https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-02.jpg"
                                            className="h-full w-full object-cover object-center"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserHome;