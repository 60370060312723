const BannerEvent = () => {
    return (
        <>
            <h2 className="font-medium text-[24px] leading-[29.26px] text-left text-[#2D2C3C] font-montserrat">
                Upload Image
            </h2>

            <input
                class="block w-full text-sm my-[30px] text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
            />

            <div>
                <p className="font-normal text-[24px] leading-[32.68px] text-left text-[#5A5A5A] font-open-sans">
                    Feature Image must be at least 1170 pixels wide by 504
                    pixels high.
                </p>
                <p className="font-normal text-[24px] leading-[32.68px] text-left text-[#5A5A5A] font-open-sans mb-[30px]">Valid file formats: JPG, GIF, PNG.</p>
            </div>
        </>
    );
};
export default BannerEvent;
