import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '../Misc/Spinner';
import { Signin } from './signin';
import {
    emailPasswordSignUp,
    doesEmailExist,
    getAuthorisationURLWithQueryParamsAndSetState,
    thirdPartySignInAndUp,
    redirectToThirdPartyLogin
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';

import { useLocation } from 'react-router-dom';
import { getWebsiteDomain } from '../../auth_config';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
    getRedirectURL,
    setRedirectURL,
    getTermsOfServiceURL
} from '../../constants';
import Logo from "../../assets/images/logo.svg"
import google from "../../assets/images/google.svg"
import facebook from "../../assets/images/facebook.svg"
import instagram from "../../assets/images/Instagram.svg"
import linkedin from "../../assets/images/linkedin.svg"
import user from "../../assets/images/Icons/user.svg"
import message from "../../assets/images/Icons/message.svg"
import eyeoff from "../../assets/images/Icons/eye-off.svg"
import downarrow from "../../assets/images/Icons/down-arrow.svg"
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';

export const Signup = ({ imagePosition }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSignup, setIsSignup] = useState(true);
    const [redirectLoading, setRedirectLoading] = useState(false);
    const [params] = useSearchParams();

    const [form, setForm] = useState({})

    useEffect(() => {
        if (params.get('redirectToPath')) {
            setRedirectURL(params.get('redirectToPath'));
        }
        const role = methodModel.getPrams("role")
        if (role) {
            setForm((prev)=>({ ...prev, role: role }))
        }
    }, []);

    // for language option
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    // for checked
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const navigate = useNavigate();

    const checkEmail = async email => {
        try {
            let response = await doesEmailExist({
                email
            });

            if (response.doesExist) {
                window.alert('Email already exists. Please sign in instead');
            }
        } catch (err) {
            console.log(err)
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.

                window.alert(err.message);
            } else {
                window.alert(err.message);
            }
        }
    };

    async function handleThirdPartyCallback() {
        try {
            setRedirectLoading(true);
            const response = await thirdPartySignInAndUp();
            if (response.status === 'OK') {
                if (response.createdNewUser) {
                    // sign up successful
                } else {
                    // sign in successful
                }
                setRedirectLoading(false);
                navigate(getRedirectURL(params.get('redirectToPath')));
            } else {
                // SuperTokens requires that the third party provider
                // gives an email for the user. If that's not the case, sign up / in
                // will fail.

                // As a hack to solve this, you can override the Backend functions to create a fake email for the user.
                setRedirectLoading(false);
                window.alert(
                    'No email provided by social login. Please use another form of login'
                );
                navigate('/auth'); // redirect back to login page
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
                setRedirectLoading(false);
            } else {
                window.alert(err);
                setRedirectLoading(false);
            }
        }
    }

    // handle google redirect
    const location = useLocation();

    useEffect(() => {
        const callbackRouteGoogle = '/auth/callback/google';
        const callBackRouteGithub = '/auth/callback/github';
        const callBackRouteApple = '/auth/callback/apple';
        const callBackRouteAtlassian = '/auth/callback/atlassian';
        if (
            location.pathname === callbackRouteGoogle ||
            location.pathname === callBackRouteGithub ||
            location.pathname === callBackRouteApple ||
            location.pathname === callBackRouteAtlassian
        ) {
            handleThirdPartyCallback();
        }
    }, [location]); // Only re-run the effect if location changes
    // Update for Atlassian sign-in
    async function thirdPartySignInClicked(name) {
        setRedirectLoading(true);
        try {
            const authUrl = await getAuthorisationURLWithQueryParamsAndSetState(
                {
                    thirdPartyId: name,
                    frontendRedirectURI:
                        getWebsiteDomain() + '/auth/callback/' + name
                }
            );

            // Redirect user to the OAuth provider
            window.location.assign(authUrl);
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                window.alert(err.message);
            } else {
                console.log(err);
            }
            setRedirectLoading(false);
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        if (!checked) {
            alert('Please accept the terms and conditions');
            setIsLoading(false);
            return;
        }
        /// check email
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            setIsLoading(false);
            return;
        }

        if (password.length < 6) {
            alert('Your password must be at least 6 characters');
            setIsLoading(false);
            return;
        }

        try {
            checkEmail(email);
            let response = await emailPasswordSignUp({
                formFields: [
                    {
                        id: 'email',
                        value: email
                    },
                    {
                        id: 'password',
                        value: password
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
                setIsLoading(false);
            } else {
                // sign up successful. The session tokens are automatically handled by
                // the frontend SDK.
                setIsLoading(false);
                navigate('/auth/verify-email?rid=emailverification');
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err)
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
            } else {
                window.alert(err);
            }
        }
    }

    const signInRedirection=()=>{
        if(form?.role){
            navigate(`/signin?role=${form?.role}`)
        }else{
            navigate('/signin')
        }
    }

    return (
        <div className="light">
            {isSignup ? (
                // <section className="md:pt-8 pb-8 md:bg-gray-50 min-h-screen">
                //     <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                //         <div className="w-full md:bg-white rounded-lg md:shadow md:mt-0 sm:max-w-lg xl:p-0">
                //             <div className="px-6 space-y-4 md:space-y-6 sm:p-8">
                //                 <a
                //                     href="#"
                //                     className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
                //                 >
                //                     <img
                //                         className="h-8 rounded p-1 mr-2"
                //                         src="/"
                //                         alt="logo"
                //                     />
                //                     blah
                //                 </a>

                //                 <h1 className="text-center text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                //                     catch phrase
                //                 </h1>

                //                 <h2 className="text-center text-xl font-semibold tracking-tight text-gray-600 dark:text-gray-400">
                //                     {!redirectLoading
                //                         ? 'Sign up'
                //                         : 'Loading your account...'}
                //                 </h2>
                //                 {!redirectLoading ? (
                //                     <form
                //                         onSubmit={handleSubmit}
                //                         className="items-center space-y-4 md:space-y-6"
                //                         action="#"
                //                     >
                //                         <div>
                //                             <div className="flex flex-col items-center justify-center">
                //                                 <button
                //                                     onClick={() =>
                //                                         thirdPartySignInClicked(
                //                                             'google'
                //                                         )
                //                                     }
                //                                     type="button"
                //                                     className="justify-center w-3/4 text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
                //                                 >
                //                                     <svg
                //                                         className="w-4 h-4 mr-2"
                //                                         aria-hidden="true"
                //                                         xmlns="http://www.w3.org/2000/svg"
                //                                         fill="currentColor"
                //                                         viewBox="0 0 18 19"
                //                                     >
                //                                         <path
                //                                             fillRule="evenodd"
                //                                             d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                //                                             clipRule="evenodd"
                //                                         />
                //                                     </svg>
                //                                     Sign in with Google
                //                                 </button>

                //                                 <div className="relative mt-8 mb-8 w-full">
                //                                     <div
                //                                         className="absolute inset-0 flex items-center"
                //                                         aria-hidden="true"
                //                                     >
                //                                         <div className="w-full border-t border-gray-200" />
                //                                     </div>
                //                                     <div className="relative flex justify-center text-sm font-medium leading-6">
                //                                         <span className="bg-white px-6 text-gray-900">
                //                                             Or continue with
                //                                         </span>
                //                                     </div>
                //                                 </div>
                //                             </div>
                //                             <label
                //                                 htmlFor="email"
                //                                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                //                             >
                //                                 Your email
                //                             </label>
                //                             <input
                //                                 type="email"
                //                                 onChange={e =>
                //                                     setEmail(e.target.value)
                //                                 }
                //                                 name="email"
                //                                 id="email"
                //                                 className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                //                                 placeholder="name@company.com"
                //                                 required=""
                //                             />
                //                         </div>
                //                         <div>
                //                             <label
                //                                 htmlFor="password"
                //                                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                //                             >
                //                                 Password
                //                             </label>
                //                             <input
                //                                 type="password"
                //                                 onChange={e =>
                //                                     setPassword(e.target.value)
                //                                 }
                //                                 name="password"
                //                                 id="password"
                //                                 className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                //                                 placeholder="••••••••"
                //                                 required=""
                //                             />
                //                         </div>
                //                         <div>
                //                             <label
                //                                 htmlFor="confirm-password"
                //                                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                //                             >
                //                                 Confirm password
                //                             </label>
                //                             <input
                //                                 type="password"
                //                                 onChange={e =>
                //                                     setConfirmPassword(
                //                                         e.target.value
                //                                     )
                //                                 }
                //                                 name="confirm-password"
                //                                 id="confirm-password"
                //                                 className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                //                                 placeholder="••••••••"
                //                                 required=""
                //                             />
                //                         </div>
                //                         <div className="flex items-start">
                //                             <div className="flex items-center h-5">
                //                                 <input
                //                                     id="terms"
                //                                     aria-describedby="terms"
                //                                     type="checkbox"
                //                                     value={checked}
                //                                     onChange={() =>
                //                                         setChecked(!checked)
                //                                     }
                //                                     className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                //                                     required=""
                //                                 />
                //                             </div>
                //                             <div className="ml-3 text-sm">
                //                                 <label
                //                                     htmlFor="terms"
                //                                     className="font-light text-gray-500 dark:text-gray-300"
                //                                 >
                //                                     I accept the{' '}
                //                                     <a
                //                                         target="_blank"
                //                                         rel="noopener noreferrer"
                //                                         className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                //                                         href={getTermsOfServiceURL()}
                //                                     >
                //                                         Terms and Conditions
                //                                     </a>
                //                                 </label>
                //                             </div>
                //                         </div>
                //                         <button
                //                             type="submit"
                //                             className={`w-full text-white ${isLoading
                //                                     ? 'bg-gray-400'
                //                                     : 'bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300'
                //                                 } font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                //                             disabled={isLoading}
                //                         >
                //                             {isLoading ? (
                //                                 <Spinner w={'4'} />
                //                             ) : (
                //                                 'Create an account'
                //                             )}
                //                         </button>

                //                         <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                //                             Already have an account?{' '}
                //                             <a
                //                                 onClick={() =>
                //                                     setIsSignup(false)
                //                                 }
                //                                 className="cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500"
                //                             >
                //                                 Login here
                //                             </a>
                //                         </p>
                //                     </form>
                //                 ) : (
                //                     <div className="flex justify-center items-center">
                //                         <Spinner
                //                             w={'20'}
                //                             className="justify-center"
                //                         />
                //                     </div>
                //                 )}
                //             </div>
                //         </div>
                //     </div>
                // </section>
                <div className="w-full bg-cover bg-[url('assets/images/createpagebg.png')] h-[100vh] flex justify-between md:flex-row flex-col" >
                    <div className="md:max-w-[624px] w-full md:ps-[48px] pt-[15px] md:pt-[30px] px-7 md:px-0">
                        <Link to="/">
                            <img src={Logo} width={261.83} height={100} alt="" />
                        </Link>
                        <div className="flex flex-col gap-[30px] mt-[50px] md:mt-[100px]">
                            <h2 className="text-[50px] font-[700] leading-[60.95px] text-white">Join Marc and Discover Local Events</h2>
                            <p className="text-[18px] font-[400] leading-[27.54px] text-white">m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et mas</p>
                        </div>
                    </div>
                    <div className="bg-white h-full w-full px-[50px] md:px-[107px] pb-[30px] md:pb-[118px] md:max-w-[652px] rounded-tl-[113.9px] md:rounded-tl-[204.9px] flex justify-center flex-col items-start mt-5 md:mt-0">
                        {/* <div className="relative ms-auto top-[25px] md:top-[79px]">
                            <button
                                onClick={toggleDropdown}
                                className="flex justify-center items-stretch  gap-1 text-[13.56px] font-[500] text-[#a09f9f]"
                            >
                                English (US)
                                <img width={11.86} height={7.63} src={downarrow} alt="" />
                            </button>

                            {isOpen && (
                                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                                            Option 1
                                        </a>
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                                            Option 2
                                        </a>
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                                            Option 3
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div> */}
                        {!redirectLoading ? (
                            <form onSubmit={handleSubmit} className="w-full pt-[30px] md:pt-[134.71px]">
                                <h2 className="text-[#005AAB] text-[25.42px] leading-31 font-[800] text-left w-full">Create Account</h2>
                                <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">


                                    <div className="relative flex">
                                        <input
                                            className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] focus:outline-none focus:border-b-2 focus:border-transparent placeholder:text-[13.56px] placeholder:font-[500]"
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="Email address"
                                        />
                                        <img className="absolute right-3 top-[18px]" src={message} alt="" />
                                    </div>
                                    <div className="relative flex">
                                        <input
                                            className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] focus:outline-none focus:border-b-2 focus:border-transparent placeholder:text-[13.56px] placeholder:font-[500]"
                                            type="password"
                                            name="password"
                                            id="password"
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                            placeholder="Password"
                                        />
                                        <img className="absolute right-3 top-[18px]" src={eyeoff} alt="" />
                                    </div>
                                    <div className="relative flex">
                                        <input
                                            className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] outline-none  focus:outline-none focus:border-b-2 focus:border-transparent placeholder:text-[13.56px] placeholder:font-[500]"
                                            type="password"
                                            name="confirm-password"
                                            id="confirm-password"
                                            onChange={e =>
                                                setConfirmPassword(e.target.value)
                                            }
                                            placeholder="Confirm Password"
                                        />
                                        <img className="absolute right-3 top-[18px]" src={eyeoff} alt="" />
                                    </div>

                                    <div className="flex gap-1">
                                        <div className="flex items-center relative">
                                            <input
                                                className="appearance-none h-4  w-4 border  border-solid rounded-[4px] checked:bg-[#c7ddf3] checked:border-[#8aa9c4]  focus:outline-none outline-none   transition-colors"
                                                style={{ backgroundColor: isChecked ? "#c7ddf3" : "" }}
                                                id="terms"
                                                aria-describedby="terms"
                                                type="checkbox"
                                                value={checked}
                                                onChange={() =>
                                                    setChecked(!checked)
                                                }
                                            />
                                            {isChecked && (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className={`h-[8px] w-[8px]  text-[#005AAB] z-10 absolute left-[3.5px]`}
                                                    viewBox="0 0 20 20"
                                                    onClick={() => setIsChecked(!isChecked)}
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M19.707 3.293a1 1 0 0 0-1.414 0l-9 9-4-4a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l10-10a1 1 0 0 0 0-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                        <label htmlFor="terms" className="text-[11.86px] font-[400] text-[#6B6B6B]">I accept the <a href={getTermsOfServiceURL()} className="text-[#005AAB] underline cursor-pointer">terms of use</a> and <span className="text-[#005AAB] underline cursor-pointer">privacy policy</span></label>
                                    </div>
                                </div>
                                <div className="flex flex-col pt-[30px] md:pt-[81px] w-full gap-4 md:gap-[35px]">
                                    <button
                                        type="submit"
                                        className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2"
                                    >
                                        Sign Up
                                    </button>
                                    <div className="flex justify-center items-center gap-5">
                                        <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                                        <span className="text-[14.4px] font-[500] text-[#005AAB]">Or Sign Up With </span>
                                        <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                                    </div>
                                    <div>
                                        <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                                            <img
                                                className='cursor-pointer'
                                                src={google}
                                                onClick={() =>
                                                    thirdPartySignInClicked(
                                                        'google'
                                                    )
                                                }
                                                alt=""
                                            />
                                            <img
                                                className='cursor-pointer'
                                                src={facebook}
                                                onClick={() =>
                                                    thirdPartySignInClicked(
                                                        'google'
                                                    )
                                                }
                                                alt=""
                                            />
                                            <img
                                                className='cursor-pointer'
                                                src={linkedin}
                                                onClick={() =>
                                                    thirdPartySignInClicked(
                                                        'google'
                                                    )
                                                }
                                                alt=""
                                            />
                                            <img
                                                className='cursor-pointer'
                                                src={instagram}
                                                onClick={() =>
                                                    thirdPartySignInClicked(
                                                        'google'
                                                    )
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    {/* <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">Already have an account? <a href='' onClick={() => setIsSignup(false)} className="text-[#005AAB] font-[700 cursor-pointer">Sign In</a></p> */}
                                    <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1">
                                        Already have an account?{' '}
                                        <a
                                            onClick={() => signInRedirection()}
                                            className="text-[#005AAB] font-[700 cursor-pointer"
                                        >
                                           Sign In
                                        </a>
                                    </p>
                                </div>

                            </form>
                        ) : (
                            <div className="absolute inset-0 flex justify-center items-center w-full h-[100vh] bg-black bg-opacity-100 ">
                                <Spinner
                                    w={'20'}
                                    className="justify-center"
                                />
                            </div>
                        )}

                    </div>
                </div>
            ) : (
                <Signin
                    setIsSignup={setIsSignup}
                    thirdPartySignInClicked={thirdPartySignInClicked}
                />
            )}
        </div>
    );
};
