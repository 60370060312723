import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import environment from '../../../environment';

const containerStyle = {
  width: '100%',
  height: '90vh'
};

function CustomMap({ locations, mapView }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: environment.map_api_key // Replace with your Google Maps API key
  });

  const [map, setMap] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapView} // View on map
      zoom={12} // Fixed zoom level
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Change location key into locations */}
      {locations.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: location.lat, lng: location.lng }}
          icon={{
            url: location.icon,
            scaledSize: new window.google.maps.Size(40, 40) // Adjust the size of the icon if necessary
          }}
          onClick={() => setSelectedLocation(location)}
        />
      ))}
      {selectedLocation && (
        <InfoWindow
          position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <div>
            <img src={selectedLocation?.icon} className='w-full h-[250px] object-cover' />
            <h2>Location Info:</h2>
            <p>{selectedLocation?.info}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : <></>;
}

export default React.memo(CustomMap);