import React from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import BackIcon from '../../assets/images/back.png';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import TicketImg from '../../assets/images/ticket-img.png';
import { NavbarDashboard } from '../Components/ApplicationUI/NavbarDashboard';
import { Link } from 'react-router-dom';

const ConfirmationPage = () => {
    return (
        <>
            <NavbarDashboard />
            <section className="bg-[#D9D9D938]">
                <div className="w-[85%] mx-auto flex items-center gap-7 py-[30px]">
                    <button>
                        <img
                            className="w-[46px] h-[46px]"
                            src={BackIcon}
                            alt=""
                        />
                    </button>
                    <h2 className="font-montserrat text-[28px] font-semibold leading-[34.13px] text-left text-black">
                        Buyer Information
                    </h2>
                </div>

                <div className="w-[85%] mx-auto flex flex-col md:flex-row gap-[70px] py-[30px]">
                    <div>
                        <div className="bg-[#E1B52433] py-[12px] px-[50px]">
                            <p className="max-w-[511px] w-full text-[#005AAB]">
                                E-tickets will be sent to your email address,
                                please make sure your email address is correct.
                            </p>
                        </div>
                        <form className="w-full">
                            <div className="flex flex-col md:flex-row gap-[20px] my-[20px]">
                                <div className="w-full flex flex-col gap-[16px]">
                                    <label
                                        htmlFor=""
                                        className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        className="p-[12px] gap-10 border-l-2 border-t-2 border-r-2 border-b-2 border-solid border-[#1B1B25] rounded-[5px]"
                                        type="text"
                                        placeholder="Rachel"
                                        name=""
                                        id=""
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-[16px]">
                                    <label
                                        htmlFor=""
                                        className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        className=" p-[12px] gap-10 border-l-2 border-t-2 border-r-2 border-b-2 border-solid border-[#1B1B25] rounded-[5px]"
                                        type="text"
                                        placeholder="Green"
                                        name=""
                                        id=""
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-[20px] my-[20px]">
                                <div className="w-full flex flex-col gap-[16px]">
                                    <label
                                        htmlFor=""
                                        className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        className=" p-[12px] gap-10 border-l-2 border-t-2 border-r-2 border-b-2 border-solid border-[#1B1B25] rounded-[5px]"
                                        type="mail"
                                        placeholder="tahirhilbi324@gmail.com"
                                        name=""
                                        id=""
                                    />
                                </div>
                                <div className="w-full flex flex-col gap-[16px]">
                                    <label
                                        htmlFor=""
                                        className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black"
                                    >
                                        Confirm Email Address
                                    </label>
                                    <input
                                        className=" p-[12px] gap-10 border-l-2 border-t-2 border-r-2 border-b-2 border-solid border-[#1B1B25] rounded-[5px]"
                                        type="mail"
                                        placeholder=""
                                        name=""
                                        id=""
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-[20px] my-[20px]">
                                <div className="md:w-[50%] w-full flex flex-col gap-[16px]">
                                    <label
                                        htmlFor=""
                                        className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        className=" p-[12px] gap-10 border-l-2 border-t-2 border-r-2 border-b-2 border-solid border-[#1B1B25] rounded-[5px]"
                                        type="text"
                                        placeholder="Rachel"
                                        name=""
                                        id=""
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-[20px] my-[20px]">
                                <div className="flex flex-col gap-[16px]">
                                    <Link to="/Payment">
                                        <button className="bg-[#005AAB] px-7 py-2 font-poppins text-[14px] font-semibold leading-[21px] text-left text-white">
                                            Buy Ticket
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="md:pl-[70px] border-l border-solid border-gray-300">
                        <h2 className="font-montserrat text-3xl font-semibold leading-[29.26px] text-left text-black">
                            Event Details
                        </h2>
                        <div className="flex gap-[20px]  py-[37px] border-b border-dashed border-[#005AAB]">
                            <img
                                className="max-w-[163px] w-full"
                                src={TicketImg}
                                alt=""
                            />
                            <div className="flex flex-col gap-[8px]">
                                <h2 className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black">
                                    Drive In Senja: Back to The Future
                                </h2>
                                <p className="flex items-center font-open-sans text-base font-normal leading-[18.2px] tracking-tight text-left text-[#1B1B25]">
                                    <img
                                        className="mr-2 w-[18px] h-[18px]"
                                        src={location}
                                        alt=""
                                    />
                                    Parkiran Utama Mall @ Alam Sutera
                                </p>
                                <p className="flex items-center font-open-sans text-base font-normal leading-[18.2px] tracking-tight text-left text-[#1B1B25]">
                                    <img
                                        className="mr-2 w-[18px] h-[18px]"
                                        src={calender}
                                        alt=""
                                    />
                                    September 22, 2021 · 20.00 - 21.56 WIB
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[20px] py-[17px] border-b border-dashed border-[#005AAB]">
                            <h3 className="font-montserrat text-base font-medium leading-[24.38px] text-left text-black">
                                Order Summary
                            </h3>
                            <div className="flex justify-between">
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    Ticket Type
                                </p>
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    2 x Paket VIP
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-start gap-[20px] py-[17px] border-b border-dashed border-[#005AAB]">
                            <div className="flex justify-between w-full">
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    Ticket Price
                                </p>
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    2 x $371.000
                                </p>
                            </div>
                            <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                Service & Handling
                            </p>
                            <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                Admin Fee
                            </p>
                        </div>
                        <div className="flex flex-col gap-[20px] py-[17px]">
                            <div className="flex justify-between">
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    Total
                                </p>
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    $742.000
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ConfirmationPage;
