import React from 'react';
import EDImg from '../../assets/images/ed-img1.png';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import HeartIcon from '../../assets/images/heart.png';
import EDuration from '../../assets/images/e-duration.png';
import EAtten from '../../assets/images/e-atten.png';
import Ecall from '../../assets/images/e-call.png';
const ReveiwEvent = () => {
    return (
        <>
            <p className="mb-[30px] font-normal text-[24px] leading-[32.68px] text-left text-[#2D2C3C] font-open-sans">
                Nearly there! Check everything’s correct.
            </p>
            <div className="flex-1">
                <img className="w-full" src={EDImg} alt="" />

                <div className="flex py-10 flex-col md:flex-row gap-5 justify-between">
                    <div className="">
                        <h2 className="font-semibold text-3xl leading-[36.57px] text-left text-black font-montserrat">
                            Drive In Senja: Back to The Future
                        </h2>
                        <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                            <img
                                className="mr-2 w-[18px] h-[18px]"
                                src={location}
                                alt=""
                            />
                            Hoston Texas
                        </p>
                        <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                            <img
                                className="mr-2 w-[18px] h-[18px]"
                                src={calender}
                                alt=""
                            />
                            September 22, 2021 · 20.00 - 21.56 WIB
                        </p>
                        <p className="w-full max-w-[570px] font-normal text-base leading-[27.54px] tracking-[0.03em] text-left text-black font-open-sans">
                            Marty travels back in time using an eccentric
                            scientist's time machine. However, he must make his
                            high-school-aged parents fall in love in order to
                            return to the present.
                        </p>
                        <div className="flex gap-[20px] pt-[25px]">
                            <a className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-2 px-6 hover:text-black text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-[16px] md:inline-flex">
                                Follow
                            </a>
                            <a
                                className={`flex items-center text-[18px] text-nowrap font-semibold hover:bg-[#005AAB] hover:text-white text-black border py-2 px-6 border-[#005AAB] rounded-[16px] md:inline-flex`}
                            >
                                <img
                                    className="mr-2 w-[18px] h-[18px] hover:filter hover:brightness-0 hover:saturate-100 hover:invert-100 hover:sepia-100 hover:hue-rotate-163 hover:brightness-109 hover:contrast-101"
                                    src={HeartIcon}
                                    alt=""
                                />
                                Like
                            </a>
                        </div>
                    </div>
                    <div className="flex justify-end items-center">
                        <div
                            className="w-[285px] flex flex-col items-center border border-[black] px-[20px] py-[20px]"
                            style={{
                                boxShadow: '6px 8px 0px 0px #E1B524',
                                borderRadius: '8px'
                            }}
                        >
                            <p>Tickets starting at</p>
                            <h3 className="font-montserrat text-lg font-semibold leading-6 text-left">
                                $212.000
                            </h3>
                            <button
                                // onClick={openModal}
                                className="mt-5 w-full bg-[#005AAB] text-center font-poppins text-base font-semibold leading-7 text-left text-white py-[10px] px-[16px] gap-10 rounded-4"
                            >
                                Buy Tickets
                            </button>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                        Event Information
                    </h2>
                    <div className="grid grid-cols-1 gap-8 mt-5 md:mt-10 md:grid-cols-3">
                        <div className="flex gap-[20px]">
                            <img
                                className="w-[45px] h-[45px]"
                                src={EDuration}
                                alt=""
                            />
                            <div className="flex flex-col">
                                <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                    Duration
                                </p>
                                <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                    20.00 - 21.56 WIB
                                </span>
                                <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                    1 hour 56 minutes
                                </span>
                            </div>
                        </div>
                        <div className="flex gap-[20px]">
                            <img
                                className="w-[45px] h-[45px]"
                                src={EAtten}
                                alt=""
                            />
                            <div className="flex flex-col">
                                <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                    Audience
                                </p>
                                <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                    This movie is suitable for
                                </span>
                                <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                    audience aged 12 and above
                                </span>
                            </div>
                        </div>
                        <div className="flex gap-[20px]">
                            <img
                                className="w-[45px] h-[45px]"
                                src={Ecall}
                                alt=""
                            />
                            <div className="flex flex-col">
                                <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                    Attention
                                </p>
                                <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                    Face mask and social distancing
                                </span>
                                <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                    are mandatory outside the car.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <h3 className="font-open-sans text-base font-semibold leading-6 tracking-tight text-left text-gray-900">
                            Description
                        </h3>
                        <p className="font-open-sans text-base font-normal leading-7 tracking-tight text-left text-black">
                            Drive-In Senja memberikan retro drive-in experience
                            yang dikemas secara modern. Penggunaan transmisi
                            radio kit, mengintegrasikan suara film ke dalam
                            radio mobil, ditambah proyektor resolusi tinggi yang
                            menyediakan pengalaman visual terbaik. Acara ini
                            merupakan sarana yang aman untuk menghabiskan waktu
                            bersama keluarga, pasangan, maupun komunitas
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ReveiwEvent;
