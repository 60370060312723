import ThirdPartyEmailPassword, {
} from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { getRedirectURL } from './constants';

export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT ||6070;
    const apiUrl =
        process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl =
        process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    return websiteUrl;
}

const atlassian = {
    id: 'atlassian',
    name: 'Atlassian'
};
const google = {
    id: 'google',
    name: 'Google'
};

const github = {
    id: 'github',
    name: 'Github'
};
// export const SuperTokensConfig = {
//     appInfo: {
//         appName: 'marc',
//         apiDomain: getApiDomain(),
//         websiteDomain: getWebsiteDomain()
//     },
//     // recipeList contains all the modules that you want to
//     // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
//     recipeList: [
//         ThirdPartyEmailPassword.init({
//             signInAndUpFeature: {
//                 providers: [github, google, atlassian],
//                 defaultToSignUp: true
//             },
//             getRedirectionURL: async context => {
//                 if (context.action === 'SUCCESS') {
//                     if (context.redirectToPath !== undefined) {
//                         // we are navigating back to where the user was before they authenticated
//                         return context.redirectToPath;
//                     }
//                     return getRedirectURL(null);
//                 }
//                 return undefined;
//             }
//         }),
//         EmailVerification.init({
//             mode: 'OPTIONAL' // or "OPTIONAL",
//         }),
//         Session.init()
//     ]
// };

export const recipeDetails = {
    docsLink:
        'https://supertokens.com/docs/thirdpartyemailpassword/introduction'
};

export const PreBuiltUIList = [
    // EmailVerificationPreBuiltUI
    // ThirdPartyEmailPasswordPreBuiltUI
];

export const EmailPasswordHeadersOverride: React.FC<any> = ({
    DefaultComponent,
    ...props
}) => {
    return (
        <div>
            <img
                style={{ height: '50px' }}
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
            />
            <h1 style={{ margin: 0 }}>pre.dev</h1>

            <DefaultComponent {...props} />
        </div>
    );
};
