import React, { useState, useEffect } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { emailPasswordSignIn } from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { getRedirectURL, setRedirectURL } from '../../constants';
// import
import Footer from '../Footer';

import InstaIcon from '../../assets/images/Icons/insta.png';
import FBIcon from '../../assets/images/Icons/face.png';
import LinkIcon from '../../assets/images/Icons/link.png';
import TwtIcon from '../../assets/images/Icons/twit.png';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import EDImg from '../../assets/images/ed-img1.png';
import HeartIcon from '../../assets/images/heart.png';
import EDuration from '../../assets/images/e-duration.png';
import EAtten from '../../assets/images/e-atten.png';
import Ecall from '../../assets/images/e-call.png';
import EDImg1 from '../../assets/images/ed-img2.png';
import EDImg2 from '../../assets/images/ed-img2.png';
import EDImg3 from '../../assets/images/ed-img3.png';
import Ticket from '../../assets/images/ticket.png';
import Star from '../../assets/images/star.png';
import prevIcon from '../../assets/images/prevIcon.png';
import nextIcon from '../../assets/images/nextIcon.png';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css';
import Modal from '../Components/Modal';
import google from '../../assets/images/google.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/linkedin.svg';
import lock from '../../assets/images/Icons/lock.svg';
import message from '../../assets/images/Icons/message.svg';
import eyeoff from '../../assets/images/Icons/eye-off.svg';
const HostProfile = ({ setIsSignup, thirdPartySignInClicked }) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (params.get('redirectToPath')) {
            setRedirectURL(params.get('redirectToPath'));
        }
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();
        if (email.length < 1) {
            alert('Please enter your email and password to login.');
            setIsLoading(false);
            return;
        }
        if (password.length < 6) {
            alert('Your password must be at least 6 characters');
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        try {
            let response = await emailPasswordSignIn({
                formFields: [
                    {
                        id: 'email',
                        value: email
                    },
                    {
                        id: 'password',
                        value: password
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
            } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
                window.alert('Email password combination is incorrect.');
                setIsLoading(false);
            } else {
                setIsLoading(true);
                navigate(getRedirectURL(params.get('redirectToPath')));
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                window.alert(err.message);
                setIsLoading(false);
            } else {
                window.alert(err);
                setIsLoading(false);
            }
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <NavigationBar />
            <div className="venue-profile bg-[#D9D9D938] py-[40px]">
                <div className="w-[85%] mx-auto flex gap-9">
                    <div className="flex flex-col max-w-[290px] pi-[20px] gap-3">
                        <p className="font-normal text-base leading-[30px] text-left text-[#1B1B25] font-poppins">
                            Share
                        </p>
                        <img className="w-[50px] h-[50px] cursor-pointer" src={InstaIcon} alt="" />
                        <img className="w-[50px] h-[50px] cursor-pointer" src={FBIcon} alt="" />
                        <img className="w-[50px] h-[50px] cursor-pointer" src={LinkIcon} alt="" />
                        <img className="w-[50px] h-[50px] cursor-pointer" src={TwtIcon} alt="" />
                    </div>
                    <div className="flex-1">
                        <img className="w-full" src={''} alt="" />

                        <div className="flex py-10 flex-col md:flex-row gap-5 justify-between">
                            <div>
                                <h2 className="font-semibold text-3xl leading-[36.57px] text-left text-black font-montserrat">
                                    DJ Flash
                                </h2>
                                <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                                    <img className="mr-2 w-[18px] h-[18px]" src={location} alt="" />
                                    Hollywood, Florida, United States
                                </p>
                                <p className="w-full max-w-[570px] font-normal text-base leading-[27.54px] tracking-[0.03em] text-left text-black font-open-sans">
                                    Known as much for his versatility as for the wit and charm he exudes, Casey Graves is an enigmatically driven stand-up comedian leaving his mark.
                                </p>
                                <div className="flex gap-[20px] pt-[25px]">
                                    <a className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-2 px-6 hover:text-black text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-[16px] md:inline-flex">
                                        Follow
                                    </a>
                                    <a className="flex items-center text-[18px] text-nowrap font-semibold hover:bg-[#005AAB] hover:text-white text-black border py-2 px-6 border-[#005AAB] rounded-[16px] md:inline-flex">
                                        <img className="mr-2 w-[18px] h-[18px]" src={''} alt="" />
                                        Like
                                    </a>
                                </div>
                            </div>
                            <div className="flex justify-end items-center">
                                <div className="w-[285px] flex flex-col items-center border border-[black] px-[20px] py-[20px]" style={{ boxShadow: '6px 8px 0px 0px #E1B524', borderRadius: '8px' }}>
                                    <p>Upcoming Events</p>
                                    <div className="flex flex-col items-center">
                                        <p className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            March 26, 7:30 PM - Thalia Mara Hall, Jackson, MS
                                        </p>
                                        <button onClick={openModal} className="mt-5 w-full bg-[#005AAB] text-center font-poppins text-base font-semibold leading-7 text-left text-white py-[10px] px-[16px] gap-10 rounded-4">
                                            Buy Tickets
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div className="flex mb-8 flex-col pt-[30px] md:pt-[81px] w-full gap-4 md:gap-[35px]">
                        <label className="text-[14.4px] font-[500] text-[#005AAB]">
                            Upload Menu
                        </label>
                        <input type="file" className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]" />
                    </div>
                        <div>
                            <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                Media
                            </h2>
                            <div className="grid grid-cols-1 gap-8 mt-5 md:mt-10 md:grid-cols-3">
                                <div className="w-full bg-gray-300 h-64"></div>
                                <div className="w-full bg-gray-300 h-64"></div>
                                <div className="w-full bg-gray-300 h-64"></div>
                                <div className="w-full bg-gray-300 h-64"></div>
                                <div className="w-full bg-gray-300 h-64"></div>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="font-open-sans text-base font-semibold leading-6 tracking-tight text-left text-gray-900">
                                About DJ Flash
                            </h3>
                            <p className="font-open-sans text-base font-normal leading-7 tracking-tight text-left text-black">
                                Known as much for his versatility as for the wit and charm he exudes, Casey Graves is an enigmatically driven stand-up comedian leaving his mark. Hosting shows at the Dania Beach Improv and performing alongside renowned talent in the South Florida area are his most notable accomplishments. However, his projected voice of confidence also permeates the set, evident in his commanding stage presence.
                            </p>
                        </div>
                        <section className="mt-8 bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
                            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                                <div className="flex items-center gap-2">
                                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Host Reviews</h2>
                                <div className="mt-2 flex items-center gap-2 sm:mt-0">
                                    <div className="flex items-center gap-0.5">
                                    {[...Array(5)].map((_, index) => (
                                        <svg key={index} className="h-4 w-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                                        </svg>
                                    ))}
                                    </div>
                                    <p className="text-sm font-medium leading-none text-gray-500 dark:text-gray-400">(4.8)</p>
                                </div>
                                </div>

                                <div className="mt-6 divide-y divide-gray-200 dark:divide-gray-700">
                                {[
                                    { name: "Micheal Gough", date: "November 18, 2023", review: "Amazing host, highly recommend!" },
                                    { name: "Jese Leos", date: "November 18, 2023", review: "Very professional and organized." },
                                    { name: "Bonnie Green", date: "November 18, 2023", review: "Had a great experience, will book again." }
                                ].map((review, index) => (
                                    <div key={index} className="gap-3 py-6 sm:flex sm:items-start">
                                    <div className="shrink-0 space-y-2 sm:w-48 md:w-72">
                                        <div className="flex items-center gap-0.5">
                                        {[...Array(5)].map((_, idx) => (
                                            <svg key={idx} className="h-4 w-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                                            </svg>
                                        ))}
                                        </div>
                                        <div className="space-y-0.5">
                                        <p className="text-base font-semibold text-gray-900 dark:text-white">{review.name}</p>
                                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">{review.date}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4 min-w-0 flex-1 space-y-4 sm:mt-0">
                                        <p className="text-base font-normal text-gray-500 dark:text-gray-400">{review.review}</p>
                                    </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                            </section>

                    </div>
                </div>
            </div>
            <Footer />
            <Modal open={isModalOpen} onClose={closeModal} title="Buy Tickets">
                <form onSubmit={handleSubmit} className="w-full py-[60px] rounded-[16px]">
                    <h2 className="text-[#005AAB] text-[25.42px] leading-31 font-[800] text-left w-full">
                        Sign In
                    </h2>
                    <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                        <div className="relative flex">
                            <input className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]" type="email" onChange={e => setEmail(e.target.value)} name="email" id="email" placeholder="Email address" />
                            <img className="absolute right-3 top-[18px]" src={message} alt="" />
                        </div>
                        <div className="relative flex">
                            <input className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]" type="password" name="password" onChange={e => setPassword(e.target.value)} id="password" placeholder="Password" />
                            <img className="absolute right-3 top-[18px]" src={eyeoff} alt="" />
                        </div>
                    </div>
                    <div className="flex justify-end mt-[10px]">
                        <img className="me-1" src={lock} alt="" />
                        <Link to="/auth/forgot-password" className="text-[11.86px] font-[400] text-[#6B6B6B]">
                            Forgot Password
                        </Link>
                    </div>
                    <div className="flex flex-col pt-[30px] md:pt-[81px] w-full gap-4 md:gap-[35px]">
                        <button type="submit" className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer">
                            Sign In
                        </button>
                        <div className="flex justify-center items-center gap-5">
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            <span className="text-[14.4px] font-[500] text-[#005AAB]">
                                Or Sign In With
                            </span>
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                        </div>
                        <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                            <img className="cursor-pointer" src={google} onClick={() => thirdPartySignInClicked('google')} alt="" />
                            <img className="cursor-pointer" src={facebook} onClick={() => thirdPartySignInClicked('google')} alt="" />
                            <img className="cursor-pointer" src={linkedin} onClick={() => thirdPartySignInClicked('google')} alt="" />
                            <img className="cursor-pointer" src={instagram} onClick={() => thirdPartySignInClicked('google')} alt="" />
                        </div>
                        <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                            Don't have an account yet?{' '}
                            <a onClick={() => setIsSignup(true)} className="cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500">
                                Sign Up
                            </a>
                        </p>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default HostProfile;
