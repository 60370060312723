import React from 'react';
import google from '../../assets/images/google.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/linkedin.svg';
import lock from '../../assets/images/Icons/lock.svg';
import message from '../../assets/images/Icons/message.svg';
import eyeoff from '../../assets/images/Icons/eye-off.svg';
import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
const ProviderSignIn = () => {
    return (
        <>
            <section className="relative pb-4 px-4 sm:px-8 w-full bg-cover bg-[url('assets/images/providerbg.png')] h-[553px] flex flex-col items-center justify-center">
                <div className="w-full absolute top-0">
                    <NavigationBar />
                </div>
                <div className="text-center space-y-4">
                    <h1 className="flex font-montserrat flex-col text-white font-bold text-4xl md:text-5xl">
                        <span className="text-[#E1B524]">
                            Unlock new opportunities for your business
                        </span>
                        sign In as a service provider today
                    </h1>
                    <p className="text-white font-montserrat max-w-xl mx-auto leading-relaxed">
                        Find Customers Near You
                    </p>
                </div>
            </section>
            <div className="flex flex-col items-center py-10">
                <h3 className="text-center font-[500] text-[25px] md:text-[44px]">
                    Lorem Ispum
                </h3>
                <p className="text-center max-w-[1236px] mx-auto w-full text-[12px] md:text-[18px] font-[400] px-3 mt-[20px]">
                    m ipsum dolor sit amet consectetur adipiscing elit Ut et mm
                    ipsum dolor sit amet consectetur adipiscing elit Ut et
                    massaassam ipsum dolor sit amet consectetur adipiscing elit
                    Ut et massa m ipsum dolor sit amet consectetur adipiscing
                    elit Ut et massa m ipsum dolor sit amet consectetur adipiscm
                    ipsum dolor sit amet t et massa m ipsum dolor sit amet
                    consectetur adipiscing elit Ut et massa
                </p>
            </div>
            <div className="w-full px-4 px-md-01 max-w-[574px] mx-auto pb-10">
                <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                    <div>
                        <label
                            htmlFor=""
                            className="text-[#555555] font-[400] mb-[12px]"
                        >
                            Username
                        </label>
                        <div className="relative flex">
                            <input
                                className="border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] bg-[#EEEEEE] focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]"
                                type="email"
                                name=""
                                id=""
                                placeholder="Email address"
                            />
                            <img
                                className="absolute right-3 top-[18px]"
                                src={message}
                                alt=""
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor=""
                            className="text-[#555555] font-[400] mb-[12px]"
                        >
                            Password
                        </label>
                        <div className="relative flex">
                            <input
                                className="border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] bg-[#EEEEEE] focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]"
                                type="password"
                                name=""
                                id=""
                                placeholder="Password"
                            />
                            <img
                                className="absolute right-3 top-[18px]"
                                src={eyeoff}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-[10px]">
                    <img className="me-1" src={lock} alt="" />
                    <p className="text-[11.86px] font-[400] text-[#6B6B6B]">
                        Forgot Password
                    </p>
                </div>
                <div className="flex flex-col pt-[10px] md:pt-[21px] w-full gap-4 md:gap-[25px]">
                    <Link
                        to="/CreateEvent"
                        className="text-[21.18px] text-center text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer"
                    >
                        Sign Up
                    </Link>

                    <div className="flex justify-center items-center gap-5">
                        <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                        <span className="text-[14.4px] font-[500] text-[#AEAEAE]">
                            Or Sign Up With{' '}
                        </span>
                        <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                    </div>
                    <div>
                        <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                            <img
                                className="cursor-pointer"
                                src={google}
                                alt=""
                            />
                            <img
                                className="cursor-pointer"
                                src={facebook}
                                alt=""
                            />
                            <img
                                className="cursor-pointer"
                                src={instagram}
                                alt=""
                            />
                            <img
                                className="cursor-pointer"
                                src={linkedin}
                                alt=""
                            />
                        </div>
                    </div>
                    <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1] mt-[30px]">
                        Do not have an account ?{' '}
                        <span className="text-[#005AAB] font-[700] cursor-pointer">
                            Sign Up
                        </span>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProviderSignIn;
