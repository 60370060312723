import React from 'react';
import Footer from '../Footer';
import BackIcon from '../../assets/images/back.png';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import TicketImg from '../../assets/images/ticket-img.png';
import CardImg from '../../assets/images/card.png';
import { NavbarDashboard } from '../Components/ApplicationUI/NavbarDashboard';
import { Link } from 'react-router-dom';

const TicketPage = () => {
    return (
        <>
            <NavbarDashboard />
            <section className="bg-[#D9D9D938]">
                <div className="w-[85%] mx-auto flex items-center gap-7 py-[30px]">
                    <button>
                        <img
                            className="w-[46px] h-[46px]"
                            src={BackIcon}
                            alt=""
                        />
                    </button>
                    <h2 className="font-montserrat text-[28px] font-semibold leading-[34.13px] text-left text-black">
                        Ticket Options
                    </h2>
                </div>
                <div className="w-[85%] mx-auto flex flex-col md:flex-row items-center gap-10 pl-[60px] my=[50px]">
                    <img
                        className="max-w-[548px] w-full"
                        src={TicketImg}
                        alt=""
                    />
                    <div className="">
                        <h2 className="font-semibold text-3xl leading-[36.57px] text-left text-black font-montserrat">
                            Drive In Senja: Back to The Future
                        </h2>
                        <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                            <img
                                className="mr-2 w-[18px] h-[18px]"
                                src={calender}
                                alt=""
                            />
                            September 22, 2021 · 20.00 - 21.56 WIB
                        </p>
                        <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                            <img
                                className="mr-2 w-[18px] h-[18px]"
                                src={location}
                                alt=""
                            />
                            Hoston Texas
                        </p>

                        <p className="w-full max-w-[570px] font-normal text-base leading-[27.54px] tracking-[0.03em] text-left text-black font-open-sans">
                            Marty travels back in time using an eccentric
                            scientist's time machine. However, he must make his
                            high-school-aged parents fall in love in order to
                            return to the present.
                        </p>
                    </div>
                </div>
                <div className="w-[85%] mx-auto grid grid-cols-2 gap-8 mt-5 md:mt-12 md:grid-cols-5">
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                    <img src={CardImg} alt="" />
                </div>
                <div className="bg-[#FBEDBC] py-[10px] flex justify-center my-[50px]">
                    <Link
                        to=""
                        className="bg-[#005AAB] px-7 py-2 font-poppins text-[14px] font-semibold leading-[21px] text-left text-white"
                    >
                        Buy Ticket
                    </Link>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default TicketPage;
