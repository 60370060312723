import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FolderIcon } from '@heroicons/react/24/solid';
import { MdDashboard } from "react-icons/md";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const Item = ({ closeSidebar, id }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const onClick = async e => {
        e.preventDefault();
        navigate(
            '/home'
        );
        if (closeSidebar) {
            closeSidebar();
        }
    };

    let active = pathname.includes('home');

    return (
        <a
            onClick={onClick}
            className={classNames(
                'text-[#005fa7] hover:bg-[#e5edfa] hover:!text-[#3b7ffa]',
                'group flex items-center gap-[12px] rounded-md px-4 py-2 text-sm font-[500] cursor-pointer',
                active ? 'bg-[#e5edfa] !text-[#3b7ffa]' : ''
            )}
        >
            <MdDashboard
                className="text-[#005fa7] w-[30px] h-[30px] p-[6px] rounded-[50%] bg-white"
                aria-hidden="true"
            />
            <span className="truncate text-inherit leading-none sidebar_text">Dashboard</span>
        </a>
    );
};
