import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import Logo from "../../assets/images/logo-nav.svg"

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { FaRegUser } from 'react-icons/fa';

export const NavigationBar = ({ }) => {
    const navigate = useNavigate()
    // const sessionContext = useSessionContext();
    const [state, setState] = useState(false)
    const token = localStorage.getItem("token")

    const navigation = [
        { title: "Home", path: token ? "/home" : "/", show: true },
        { title: "About Us", path: "/", show: true },
        { title: "Events", path: "/Event", show: true },
        { title: "Venues", path: "/providersignup", show: false },
        { title: "Service Provider", path: "/ProviderLanding", show: true },
    ]
    const currentUrl = window.location.pathname;

    useEffect(() => {
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".menu-btn")) setState(false);
        };
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }, [])

    return (
        <nav className={`backdrop-blur-md font-poppins shadow-md pt-4 pb-4 md:text-sm ${state ? "shadow-lg rounded-xl bg-white border mt-2 md:shadow-none md:border-none md:mx-2 md:mt-0" : "bg-[#ffffff59]"}`}>
            <div className="gap-x-14 items-center w-[90%] mx-auto px-2 md:flex md:px-8">
                <div className="flex w-full items-center  justify-between py-1 md:block">
                    <Link to="/">
                        <img
                            src={Logo}
                            width={120}
                            height={50}
                            alt="Float UI logo"
                        />

                    </Link>
                    <div className="md:hidden">
                        <button className={`menu-btn ${state ? "text-[#005AAB]" : "text-[white]"}`} onClick={() => setState(!state)}>
                            {
                                state ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                    </svg>
                                )
                            }
                        </button>
                    </div>
                </div>
                <ul className={`justify-center items-center space-y-6 md:flex gap-7 md:space-y-0 ${state ? 'block' : 'bg-[transparent] hidden'}`}>
                    {
                        navigation.map((item, id) => {
                            return (
                                currentUrl === "/Event" || currentUrl === "/EventDetails" || currentUrl === "/EventFilter" ? (
                                    <>
                                        {token && !item?.show ? null :
                                            <li key={id} className="text-[18px] text-nowrap font-[500]">
                                                <Link
                                                    to={item.path}
                                                    className={`block ${state ? 'text-[#343434] hover:text-[#005AAB]' : 'text-[#343434] hover:text-[#005AAB]'}`}
                                                >
                                                    {item.title}
                                                </Link>
                                            </li>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {token && !item?.show ? null :
                                            <li key={id} className="text-[18px] text-nowrap font-[500]">
                                                <Link
                                                    to={item.path}
                                                    className={`block ${state ? 'text-[#343434] hover:text-[#005AAB]' : ' text-[#343434] hover:text-[#005AAB]'}`}
                                                >
                                                    {item.title}
                                                </Link>
                                            </li>
                                        }
                                    </>
                                )
                            );
                        })
                    }

                </ul>
                <div className={`flex flex-col w-full justify-evenly mt-5 items-center md:mt-0 md:flex ${state ? 'block' : 'hidden'} `}>
                    {!localStorage.getItem('token') &&
                        <div className='flex w-full gap-x-6 items-center justify-center  md:justify-end md:space-y-0 md:mt-0'>
                            <Menu>
                                <MenuButton className="items-center block text-[18px] text-nowrap font-semibold text-[#005AAB] hover:text-white hover:bg-[#005AAB] border py-2 px-5 border-[#005AAB] rounded-full md:inline-flex">
                                    Sign In <ChevronDownIcon className="size-4" />
                                </MenuButton>
                                <MenuItems transition anchor="bottom end" className="w-52 origin-top-right rounded-xl border border-white bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0" >
                                    <MenuItem>
                                        <button onClick={() => { navigate("/signin?role=venue") }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 hover:bg-[#005aab1a]">
                                            <FaRegUser className="size-4 fill-[#005AAB]" />
                                            Sign In As Venue
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button onClick={() => { navigate("/signin?role=host") }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 hover:bg-[#005aab1a]">
                                            <FaRegUser className="size-4 fill-[#005AAB]" />
                                            Sign In As Host
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </Menu>
                            <Menu>
                                <MenuButton className="items-center flex justify-center text-[18px] text-nowrap font-semibold py-2 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                    Sign Up <ChevronDownIcon className="size-4" />
                                </MenuButton>
                                <MenuItems transition anchor="bottom end" className="w-52 origin-top-right rounded-xl border border-white bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0">
                                    <MenuItem>
                                        <button onClick={() => { navigate("/providersignup") }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 hover:bg-[#005aab1a]">
                                            <FaRegUser className="size-4 fill-[#005AAB]" />
                                            Sign Up As Venue
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button onClick={() => { navigate("/auth?role=host") }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 hover:bg-[#005aab1a]">
                                            <FaRegUser className="size-4 fill-[#005AAB]" />
                                            Sign Up As Host
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </Menu>
                        </div>
                    }
                    {/* <div className="flex w-full gap-x-6 items-center justify-center  md:justify-end md:space-y-0 md:mt-0">
                        {
                            (currentUrl === "/ProviderLanding" || currentUrl === "/providersignin" || currentUrl === "/UserSignUp") ? (
                                <>
                                    <Link to="/providersignin" className={`block text-[18px] text-nowrap font-semibold ${currentUrl === "/Event" || currentUrl === "/EventDetails" || currentUrl === "/EventFilter" ? 'text-black' : (state ? 'text-[#005AAB]' : 'text-white')} hover:bg-[#005AAB] border py-2 px-5 border-[#005AAB] rounded-full md:inline-flex`}>
                                        Sign In
                                    </Link>
                                    <Link to="/providersignup" className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-2 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                        Sign Up
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to={"/signin"} className={`block text-[18px] text-nowrap font-semibold ${currentUrl === "/Event" || currentUrl === "/EventDetails" || currentUrl === "/EventFilter" ? 'text-black' : (state ? 'text-[#005AAB]' : 'text-white')} hover:bg-[#005AAB] border py-2 px-5 border-[#005AAB] rounded-full md:inline-flex`}>
                                        Sign In
                                    </Link>
                                    <Link to="/auth" className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-2 px-5 text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">
                                        Sign Up
                                    </Link>
                                </>
                            )
                        }
                    </div> */}
                </div>
            </div >
        </nav >
    );
};
