import { Settings } from './SidebarItems/Settings';
import { Link } from 'react-router-dom';
import { SidebarItems } from './SidebarItems';
import logo from "../../../assets/images/logo-nav.svg"

export default function DesktopSidebar() {
    return (
        <div className="hidden lg:block lg:inset-y-0 lg:flex w-72 lg:flex-col h-screen">
            <div className="flex grow flex-col border-dashed border-r border-[#afafaf] bg-[#f8f9fa] px-6">
                <div className="flex justify-between items-center text-white mx-auto mt-3">
                    <div className="flex h-16 shrink-0 items-center">
                        <Link to="/" className="flex justify-end">
                            <img src={logo} width={120} height={50} alt="Company Logo" />
                        </Link>
                    </div>
                </div>
                <nav className="flex flex-1 flex-col h-full text-[#005fa7]">
                    <ul role="list" className="flex flex-1 flex-col">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                <SidebarItems />
                            </ul>
                        </li>
                        <li className="-mx-6 mt-auto border-t border-gray-300">
                            {/* <div className="mx-6 mt-4"></div>
                            <div className="mx-6 mt-4"></div> */}
                            <Settings />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}