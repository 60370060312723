import React, { useState } from 'react';
import { Dropdown, Navbar, Avatar } from 'flowbite-react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import Logo from '../../../assets/images/logo-nav.svg';
import searchIcon from '../../../assets/images/Icons/magnifier.svg';
import calender from '../../../assets/images/Icons/calender.svg';
import navHeart from '../../../assets/images/nav-heart.png';
import navTicket from '../../../assets/images/nav-ticket.png';
import navCircle from '../../../assets/images/add-circle.png';

export const NavbarDashboard = ({ menuItems = [], onMenuItemClick }) => {
    const navigate = useNavigate();
    const location = useLocation(); // Use useLocation to get the current path
    const [activeMenuItem, setActiveMenuItem] = useState({});
    const [inputType, setInputType] = useState('text');

    const navigation = [
        { title: 'Home', path: '/' },
        { title: 'About Us', path: '/' },
        { title: 'Events', path: '/Event' },
        { title: 'Venues', path: '/' },
        { title: 'Service Provider', path: '/ProviderLanding' }
    ];

    const handleInputClick = () => {
        setInputType('date');
    };

    async function onLogout() {
        await signOut();
        window.location.href = '/';
    }

    const handleClickMenuItem = index => {
        setActiveMenuItem(index);
        onMenuItemClick(index);
        navigate(menuItems[index].label);
    };

    const currentUrl = location.pathname; // Get the current URL from useLocation
    const navbarBgColor = currentUrl === '/CreateEvent' ? '#DBDBDB' : 'white'; // Conditional background color

    return (
        <Navbar
            style={{ backgroundColor: navbarBgColor }}
            className="w-full z-40 sticky top-0 p-4 border-b border-gray-200"
        >
            <div className="w-[90%] mx-auto flex items-center justify-between">
                <div>
                    <Link to="/">
                        <img
                            src={Logo}
                            width={120}
                            height={50}
                            alt="Float UI logo"
                        />
                    </Link>
                </div>
                <div>
                    {currentUrl === '/CreateEvent' ? (
                        <ul className="justify-center items-center space-y-6 md:flex gap-5 md:space-y-0">
                            {navigation.map((item, id) => (
                                <li
                                    key={id}
                                    className="text-[18px] text-nowrap font-semibold"
                                >
                                    <Link
                                        to={item.path}
                                        className="block text-[#343434] hover:text-[#005AAB]"
                                    >
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="flex flex-col sm:flex-row justify-center gap-2">
                            <div className="relative">
                                <span className="absolute top-5">
                                    <img
                                        className="ml-3"
                                        src={calender}
                                        alt=""
                                    />
                                </span>
                                <input
                                    className="mt-2 block max-w-[105px] pl-11 h-[46px] py-2 px-3 text-white placeholder-white bg-[#005AAB] border border-[#005AAB] rounded-full dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                    placeholder="Date"
                                    type={inputType}
                                    onClick={handleInputClick}
                                />
                            </div>

                            <div className="relative flex items-center mt-2 max-w-[485px] w-full">
                                <span className="absolute">
                                    <img
                                        className="ml-3"
                                        src={searchIcon}
                                        alt=""
                                    />
                                </span>
                                <input
                                    type="text"
                                    placeholder="Search an Event"
                                    className="block w-full py-2.5 text-white placeholder-white bg-[#005AAB] border border-[#005AAB] rounded-full pl-11 pr-5 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                />
                                <button className="absolute right-1 bg-white rounded-full px-[21px] py-[5px]">
                                    GO
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <ul className="flex gap-3">
                    {currentUrl === '/CreateEvent' ? (
                        <li className="flex items-center gap-2">
                            <img
                                className="w-[24px] h-[24px]"
                                src={navCircle}
                                alt=""
                            />
                            <p className="font-medium text-base leading-[27px] text-left text-[#444444] font-poppins">
                                Create Event
                            </p>
                        </li>
                    ) : (
                        <>
                            <li className="flex items-center gap-2">
                                <img
                                    className="w-[24px] h-[24px]"
                                    src={navTicket}
                                    alt=""
                                />
                                <p className="font-medium text-base leading-[27px] text-left text-[#444444] font-poppins">
                                    My Tickets
                                </p>
                            </li>

                            <li className="flex items-center gap-2">
                                <img
                                    className="w-[24px] h-[24px]"
                                    src={navHeart}
                                    alt=""
                                />
                                <p className="font-medium text-base leading-[27px] text-left text-[#444444] font-poppins">
                                    Liked Events
                                </p>
                            </li>
                        </>
                    )}
                </ul>
                <div className="flex md:order-4">
                    <Dropdown
                        inline
                        label={
                            <div className="flex items-center">
                                <Avatar
                                    className="w-11 h-11 rounded-full"
                                    alt="User settings"
                                    img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                                    rounded
                                />
                                <span className="ml-3 text-sm font-medium">
                                    Bonnie Green
                                </span>
                            </div>
                        }
                    >
                        <Dropdown.Header>
                            <span className="block text-sm">Bonnie Green</span>
                            <span className="block truncate text-sm font-medium">
                                name@flowbite.com
                            </span>
                        </Dropdown.Header>
                        {menuItems.map((item, index) => (
                            <Dropdown.Item
                                onClick={() => handleClickMenuItem(index)}
                                key={index}
                            >
                                {item.label}
                            </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={onLogout}>
                            Sign out
                        </Dropdown.Item>
                    </Dropdown>
                </div>
            </div>
        </Navbar>
    );
};
