import React from "react";
import Logo from "../../assets/images/logo.svg"
import PhoneIcon from "../../assets/images/Icons/phoneIcon.svg";
import MailIcon from "../../assets/images/Icons/mailIcon.svg";
import InstaIcon from "../../assets/images/Icons/instagramIcon.svg";
import FBIcon from "../../assets/images/Icons/facebookIcon.svg";
import LinkIcon from "../../assets/images/Icons/linkedinIcon.svg";
import TwtIcon from "../../assets/images/Icons/twitterIcon.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="bg-[#2B2B2B]">
        <div className="py-12 mx-auto">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4 w-[90%] mx-auto">
            <div className="sm:col-span-2">
              <div className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0">
                <img className="max-w-[272px]" src={Logo} alt="logo" />
                <p className="text-white max-w-[429px] w-full mt-[20px]">
                  Simplifying the event planning process and provide a one-stop solution for individuals and organizations looking to host any type of event.
                </p>
              </div>
            </div>

            <div className="w-auto">
              <p className="font-semibold text-white">Quick Link</p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <a href="" className="transition-colors duration-300 text-white dark:hover:text-blue-400 hover:underline hover:text-blue-500">
                  About Us
                </a>
                <a href="" className="transition-colors duration-300 text-white dark:hover:text-blue-400 hover:underline hover:text-blue-500">
                  Events
                </a>
                <a href="" className="transition-colors duration-300 text-white dark:hover:text-blue-400 hover:underline hover:text-blue-500">
                  Venues
                </a>
                <Link to="/providersignin" className="transition-colors duration-300 text-white dark:hover:text-blue-400 hover:underline hover:text-blue-500">
                  Sign In
                </Link>
                <Link to="/providersignup" className="transition-colors duration-300 text-white dark:hover:text-blue-400 hover:underline hover:text-blue-500">
                  Sign Up
                </Link>

              </div>

            </div>

            <div className="flex justify-start md:justify-end">
              <div className="w-auto">
                <p className="font-semibold text-white">Contact</p>

                <div className="flex flex-col items-start mt-5 space-y-2">
                  <a href="#" className="flex gap-[10px] transition-colors duration-300 text-white">
                    <img src={MailIcon} alt="" />
                    info@gmail.com
                  </a>
                  <a href="#" className="flex gap-[10px] transition-colors duration-300 text-white">
                    <img src={PhoneIcon} alt="" />
                    +1 408 XXX XXXX
                  </a>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-6 border-gray-200 md:my-8 dark:border-gray-700" />

          <div className="flex flex-col md:flex-row items-center justify-between w-[92%] mx-auto">
            <a href="#">
              <p className="text-white">ipsum dolor sit amet consectetur</p>
            </a>

            <div className="flex mx-2 mt-4 md:mt-0">
              <a href="#" className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400" aria-label="instagram">
                <img src={InstaIcon} alt="" />
              </a>

              <a href="#" className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400" aria-label="Facebook">
                <img src={FBIcon} alt="" />
              </a>

              <a href="#" className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400" aria-label="linkedin">
                <img src={LinkIcon} alt="" />
              </a>
              <a href="#" className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400" aria-label="twitter">
                <img src={TwtIcon} alt="" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;