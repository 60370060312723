import React from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import BackIcon from '../../assets/images/back.png';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import TicketImg from '../../assets/images/ticket-img.png';
import img1 from '../../assets/images/payment-img2.png';
import img2 from '../../assets/images/payment-img3.png';
import img3 from '../../assets/images/payment-img4.png';
import { NavbarDashboard } from '../Components/ApplicationUI/NavbarDashboard';
import { Link } from 'react-router-dom';
const PaymentPage = () => {
    return (
        <>
            <NavbarDashboard />
            <section className="bg-[#D9D9D938]">
                <div className="w-[85%] mx-auto flex items-center gap-7 py-[30px]">
                    <button>
                        <img
                            className="w-[46px] h-[46px]"
                            src={BackIcon}
                            alt=""
                        />
                    </button>
                    <h2 className="font-montserrat text-[28px] font-semibold leading-[34.13px] text-left text-black">
                        Buyer Information
                    </h2>
                </div>

                <div className="w-[85%] mx-auto flex flex-col md:flex-row gap-[70px] py-[30px]">
                    <form className="w-full ">
                        <div className="flex flex-col gap-[20px] my-[20px]">
                            <h3 className="font-montserrat text-3xl font-semibold leading-[29.26px] text-left">
                                Credit Card
                            </h3>

                            <div class="bg-white py-[13px] px-[18px] flex items-center gap-[18px] rounded-[8px] border border-solid border-gray-500">
                                <input
                                    id="bordered-radio-1"
                                    type="checkbox"
                                    value=""
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="bordered-radio-1"
                                    className="w-full font-open-sans text-base font-normal leading-[24.48px] tracking-tight text-left text-[#1B1B25]"
                                >
                                    Credit/Debit Card
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[20px] my-[20px]">
                            <h3 className="font-montserrat text-3xl font-semibold leading-[29.26px] text-left">
                                Virtual Account
                            </h3>

                            <div class="relative bg-white py-[13px] px-[18px] flex items-center gap-[18px] rounded-[8px] border border-solid border-gray-500">
                                <input
                                    id="bordered-radio-3"
                                    type="radio"
                                    value=""
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="bordered-radio-3"
                                    className="w-full font-open-sans text-base font-normal leading-[24.48px] tracking-tight text-left text-[#1B1B25]"
                                >
                                    PayPal
                                </label>
                                <img
                                    className="absolute right-2 top-3 w-[80px]"
                                    src={img1}
                                    alt=""
                                />
                            </div>

                            <div class="relative bg-white py-[13px] px-[18px] flex items-center gap-[18px] rounded-[8px] border border-solid border-gray-500">
                                <input
                                    id="bordered-radio-2"
                                    type="radio"
                                    value=""
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="bordered-radio-2"
                                    className="w-full font-open-sans text-base font-normal leading-[24.48px] tracking-tight text-left text-[#1B1B25]"
                                >
                                    Venmo
                                </label>
                                <img
                                    className="absolute right-2 top-3 w-[72px]"
                                    src={img2}
                                    alt=""
                                />
                            </div>

                            <div class="relative bg-white py-[13px] px-[18px] flex items-center gap-[18px] rounded-[8px] border border-solid border-gray-500">
                                <input
                                    id="bordered-radio-4"
                                    type="radio"
                                    value=""
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="bordered-radio-4"
                                    className="w-full font-open-sans text-base font-normal leading-[24.48px] tracking-tight text-left text-[#1B1B25]"
                                >
                                    Bank Account
                                </label>
                                <img
                                    className="absolute right-2 top-3 w-[30px]"
                                    src={img3}
                                    alt=""
                                />
                            </div>

                            <div class=" bg-white py-[13px] px-[18px] flex items-center gap-[18px] rounded-[8px] border border-solid border-gray-500">
                                <input
                                    id="bordered-radio-5"
                                    type="radio"
                                    value=""
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="bordered-radio-5"
                                    className="w-full font-open-sans text-base font-normal leading-[24.48px] tracking-tight text-left text-[#1B1B25]"
                                >
                                    Other
                                </label>
                            </div>
                        </div>
                    </form>
                    <div className="w-full md:pl-[70px] border-l border-solid border-gray-300">
                        <h2 className="font-montserrat text-3xl font-semibold leading-[29.26px] text-left text-black">
                            Event Details
                        </h2>
                        <div className="flex gap-[20px]  py-[37px] border-b border-dashed border-[#005AAB]">
                            <img
                                className="max-w-[163px] w-full"
                                src={TicketImg}
                                alt=""
                            />
                            <div className="flex flex-col gap-[8px]">
                                <h2 className="font-montserrat text-base font-medium leading-[19.5px] text-left text-black">
                                    Drive In Senja: Back to The Future
                                </h2>
                                <p className="flex items-center font-open-sans text-base font-normal leading-[18.2px] tracking-tight text-left text-[#1B1B25]">
                                    <img
                                        className="mr-2 w-[18px] h-[18px]"
                                        src={location}
                                        alt=""
                                    />
                                    Parkiran Utama Mall @ Alam Sutera
                                </p>
                                <p className="flex items-center font-open-sans text-base font-normal leading-[18.2px] tracking-tight text-left text-[#1B1B25]">
                                    <img
                                        className="mr-2 w-[18px] h-[18px]"
                                        src={calender}
                                        alt=""
                                    />
                                    September 22, 2021 · 20.00 - 21.56 WIB
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[20px] py-[17px] border-b border-dashed border-[#005AAB]">
                            <h3 className="font-montserrat text-base font-medium leading-[24.38px] text-left text-black">
                                Order Summary
                            </h3>
                            <div className="flex justify-between">
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    Ticket Type
                                </p>
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    2 x Paket VIP
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-start gap-[20px] py-[17px] border-b border-dashed border-[#005AAB]">
                            <div className="flex justify-between w-full">
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    Ticket Price
                                </p>
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    2 x $371.000
                                </p>
                            </div>
                            <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                Service & Handling
                            </p>
                            <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                Admin Fee
                            </p>
                        </div>
                        <div className="flex flex-col gap-[20px] py-[17px]">
                            <div className="flex justify-between">
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    Total
                                </p>
                                <p className="font-open-sans text-base font-normal leading-[27.54px] tracking-tight text-right">
                                    $742.000
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-[20px] my-[20px]">
                            <div className="flex flex-col gap-[16px]">
                                <Link to="/PaymentConfirm">
                                    <button className="bg-[#005AAB] px-7 py-2 font-poppins text-[14px] font-semibold leading-[21px] text-left text-white">
                                        Pay Now
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PaymentPage;
