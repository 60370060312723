import React, { useState, useEffect } from "react";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Footer from "../Footer";
import { NavigationBar } from "../Landing/NavigationBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../Auth/Upload";
import { Spinner } from "../Components/Spinner";
import { updateVenue } from "../../redux/actions/updateVenue";
import { IoIosStar } from "react-icons/io";
import { IoIosStarOutline } from "react-icons/io";
import { IoHandRightOutline } from "react-icons/io5";
import {
    emailPasswordSignUp,
    doesEmailExist,
    getAuthorisationURLWithQueryParamsAndSetState,
    thirdPartySignInAndUp,
    redirectToThirdPartyLogin
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { selectVenue } from '../../redux/reducers/generic/project';

const VenueHome = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [fileKey, setFileKey] = useState(null);

    const [formData, setFormData] = useState({
        locationCity: "",
        locationCitySection: "",
        locationState: "",
        business: "",
        businessName: "",
        category: "",
        hostOrVenue: "",
        website: "",
        instagram: "",
        facebook: "",
        tiktok: "",
        youtube: "",
        contactPhone: "",
        contactEmail: "",
        address: "",
        hours: "",
        bestTimeToVisit: "",
        multiLocations: "",
        hasFood: "",
        priceRange: "",
        companyEIN: "", // Added companyEIN field
    });
    const navigate = useNavigate();
    // const venue = useSelector(selectVenue);
    
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    // useEffect(() => {
    //     if (venue) {
    //         setFormData({
    //             locationCity: venue.locationCity || "",
    //             locationCitySection: venue.locationCitySection || "",
    //             locationState: venue.locationState || "",
    //             business: venue.business || "",
    //             businessName: venue.businessName || "",
    //             category: venue.category || "",
    //             hostOrVenue: venue.hostOrVenue || "",
    //             website: venue.website || "",
    //             instagram: venue.instagram || "",
    //             facebook: venue.facebook || "",
    //             tiktok: venue.tiktok || "",
    //             youtube: venue.youtube || "",
    //             contactPhone: venue.contactPhone || "",
    //             contactEmail: venue.contactEmail || "",
    //             address: venue.address || "",
    //             hours: venue.hours || "",
    //             bestTimeToVisit: venue.bestTimeToVisit || "",
    //             multiLocations: venue.multiLocations || "",
    //             hasFood: venue.hasFood || "",
    //             priceRange: venue.priceRange || "",
    //             companyEIN: venue.companyEIN || "",
    //         });
    //     }
    // }, [venue]);

    const handleSubmit = async(e) => {
        e.preventDefault();

        setLoading(true);

        const {...formDataWithoutPasswords } = formData;
        formDataWithoutPasswords['fileKeyID'] = fileKey;
        await dispatch(updateVenue(formDataWithoutPasswords));
        setLoading(false);

    };

    const checkEmail = async email => {
        try {
            let response = await doesEmailExist({
                email
            });

            if (response.doesExist) {
                window.alert('Email already exists. Please sign in instead');
            }
        } catch (err) {
            console.log(err)
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.

                window.alert(err.message);
            } else {
                window.alert(err.message);
            }
        }
    };

    return (
        // <form onSubmit={handleSubmit}>
        //     <div className="space-y-12">
        //         <div className="border-b border-gray-900/10 pb-12">
        //         <div className="border-b-[1px] border-[#d1d5db] pb-4">
        //             <h2 className="text-[20px] font-semibold leading-7 text-[#005AAB]">Venue Profile</h2>
        //             <p className="text-sm leading-6 text-gray-600">
        //                Update the details of your venue here and save your changes.
        //             </p>
        //             </div>
        //             <div className="mt-8 grid grid-cols-12 gap-x-3 gap-y-3">
        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="locationCity" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Location City
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="locationCity"
        //                             name="locationCity"
        //                             type="text"
        //                             placeholder="Enter Location City.."
        //                             value={formData.locationCity}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="locationCitySection" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Location City Section
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="locationCitySection"
        //                             name="locationCitySection"
        //                             type="text"
        //                             placeholder="Enter Location City Section"
        //                             value={formData.locationCitySection}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="locationState" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Location State
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="locationState"
        //                             name="locationState"
        //                             type="text"
        //                             placeholder="Enter Location State"
        //                             value={formData.locationState}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="business" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Business
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="business"
        //                             name="business"
        //                             type="text"
        //                             placeholder="Enter Business"
        //                             value={formData.business}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="businessName" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Business Name
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="businessName"
        //                             name="businessName"
        //                             type="text"
        //                             placeholder="Enter Business Name"
        //                             value={formData.businessName}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Category
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="category"
        //                             name="category"
        //                             type="text"
        //                             placeholder="Enter Category"
        //                             value={formData.category}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="hostOrVenue" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Host or Venue
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="hostOrVenue"
        //                             name="hostOrVenue"
        //                             type="text"
        //                             placeholder="Enter Host or Venue"
        //                             value={formData.hostOrVenue}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Website
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="website"
        //                             name="website"
        //                             type="text"
        //                             placeholder="Enter Website"
        //                             value={formData.website}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Instagram
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="instagram"
        //                             name="instagram"
        //                             type="text"
        //                             placeholder="Enter Instagram"
        //                             value={formData.instagram}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="facebook" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Facebook
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="facebook"
        //                             name="facebook"
        //                             type="text"
        //                             placeholder="Enter Facebook"
        //                             value={formData.facebook}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="tiktok" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Tiktok
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="tiktok"
        //                             name="tiktok"
        //                             type="text"
        //                             placeholder="Enter Tiktok"
        //                             value={formData.tiktok}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="youtube" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Youtube
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="youtube"
        //                             name="youtube"
        //                             type="text"
        //                             placeholder="Enter Youtube"
        //                             value={formData.youtube}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="contactPhone" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Contact Phone
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="contactPhone"
        //                             name="contactPhone"
        //                             type="text"
        //                             placeholder="Enter Contact Phone"
        //                             value={formData.contactPhone}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="contactEmail" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Contact Email
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="contactEmail"
        //                             name="contactEmail"
        //                             type="email"
        //                             placeholder="Enter Contact Email"
        //                             value={formData.contactEmail}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Address
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="address"
        //                             name="address"
        //                             type="text"
        //                             placeholder="Enter Address"
        //                             value={formData.address}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="hours" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Hours
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="hours"
        //                             name="hours"
        //                             type="text"
        //                             placeholder="Enter Hours"
        //                             value={formData.hours}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="bestTimeToVisit" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Best Time to Visit
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="bestTimeToVisit"
        //                             name="bestTimeToVisit"
        //                             type="text"
        //                             placeholder="Enter Best Time to Visit"
        //                             value={formData.bestTimeToVisit}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="multiLocations" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Multi Locations
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="multiLocations"
        //                             name="multiLocations"
        //                             type="text"
        //                             placeholder="Enter Multi Locations"
        //                             value={formData.multiLocations}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="hasFood" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Has Food (Y/N)
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="hasFood"
        //                             name="hasFood"
        //                             type="text"
        //                             placeholder="Enter Y or N"
        //                             value={formData.hasFood}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="priceRange" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Price Range ($$)
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="priceRange"
        //                             name="priceRange"
        //                             type="text"
        //                             placeholder="Enter Price Range"
        //                             value={formData.priceRange}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>



        //                 <div className="col-span-12 sm:col-span-6 xl:col-span-4">
        //                     <label htmlFor="companyEIN" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Company EIN
        //                     </label>
        //                     <div className="mt-2">
        //                         <input
        //                             id="companyEIN"
        //                             name="companyEIN"
        //                             type="text"
        //                             placeholder="Enter Company EIN"
        //                             value={formData.companyEIN}
        //                             onChange={handleChange}
        //                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        //                         />
        //                     </div>
        //                 </div>

        //                 <div className="col-span-12">
        //                     <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
        //                         Upload Government ID/Driver's License
        //                     </label>
        //                     <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        //                         <div className="text-center">
        //                             <Upload setFileKey={setFileKey} isUploading={isUploading} setUploading={setUploading} />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>

        //     <div className="mt-5 px-8 flex items-center justify-end gap-x-6 mb-5">
          
        //         <button
        //             type="submit"
        //             className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        //             disabled={isLoading}
        //         >
        //             Save Venue
        //             {isLoading && <div className="ml-2 inline"><Spinner w='4' h='4'/></div>}
        //         </button>
        //     </div>
        // </form>

        <div>
        <h4 className="text-2xl font-bold mb-3 flex items-center capitalize gap-2">
          <IoHandRightOutline className="text-3xl slow-shake text-[#8DC641]" />
          <span className="">Hi,</span> ...
        </h4>

        <main className="space-y-6">
      <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between">
        <div className="mr-6">
          <h2 className="text-[14px] text-[#838383]">This is an example dashboard created using build-in elements and components.</h2>
        </div>
     
      </div>
      <section className="grid grid-cols-12 gap-6">
        <div className='col-span-12 sm:col-span-6 lg:col-span-4 bg-white shadow rounded-lg'>
          <div className='rounded-lg bg-card flex justify-between items-center p-3'>
            <div>
             <div className='flex items-center'>
             <img src="https://assets.codepen.io/3685267/mock_faces_0.jpg" alt="" className="w-10 h-10 rounded-full object-cover"/>
             <div className='ml-2'>
              <div className='flex items-center text-[15px] font-[500] leading-[16px]'>Esther Howard</div>
              <div className='text-[12px] text-[#838383]'>USA</div>
             </div>
             </div>
            </div>
            <div className='flex flex-col items-center'>
            <div className="text-[#8DC641] flex gap-1 mb-2">
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStarOutline />
            <IoIosStarOutline />
            </div>
            <div className="text-[12px] text-[#838383]">Last 6 month</div>
            </div>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 lg:col-span-4 bg-white shadow rounded-lg'>
          <div className='rounded-lg bg-card flex justify-between items-center p-3'>
            <div>
             <div className='flex items-center'>
             <img src="https://assets.codepen.io/3685267/mock_faces_2.jpg" alt="" className="w-10 h-10 rounded-full object-cover"/>
             <div className='ml-2'>
              <div className='flex items-center text-[15px] font-[500] leading-[16px]'>Eleanor Pena</div>
              <div className='text-[12px] text-[#838383]'>USA</div>
             </div>
             </div>
            </div>
            <div className='flex flex-col items-center'>
            <div className="text-[#8DC641] flex gap-1 mb-2">
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStarOutline />
            <IoIosStarOutline />
            </div>
            <div className="text-[12px] text-[#838383]">Last 6 month</div>
            </div>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 lg:col-span-4 bg-white shadow rounded-lg'>
          <div className='rounded-lg bg-card flex justify-between items-center p-3'>
            <div>
             <div className='flex items-center'>
             <img src="https://media.istockphoto.com/id/1476170969/photo/portrait-of-young-man-ready-for-job-business-concept.webp?b=1&s=170667a&w=0&k=20&c=FycdXoKn5StpYCKJ7PdkyJo9G5wfNgmSLBWk3dI35Zw=" alt="" className="w-10 h-10 rounded-full object-cover"/>
             <div className='ml-2'>
              <div className='flex items-center text-[15px] font-[500] leading-[16px]'>Robert Fox</div>
              <div className='text-[12px] text-[#838383]'>USA</div>
             </div>
             </div>
            </div>
            <div className='flex flex-col items-center'>
            <div className="text-[#8DC641] flex gap-1 mb-2">
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStarOutline />
            <IoIosStarOutline />
            </div>
            <div className="text-[12px] text-[#838383]">Last 6 month</div>
            </div>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 lg:col-span-4 bg-white shadow rounded-lg'>
          <div className='rounded-lg bg-card flex justify-between items-center p-3'>
            <div>
             <div className='flex items-center'>
             <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH2uf_wifKp7Sy76i0OUlzVp2JlWz7LXYRLQ&s" alt="" className="w-10 h-10 rounded-full object-cover"/>
             <div className='ml-2'>
              <div className='flex items-center text-[15px] font-[500] leading-[16px]'>Jerry Wilson</div>
              <div className='text-[12px] text-[#838383]'>USA</div>
             </div>
             </div>
            </div>
            <div className='flex flex-col items-center'>
            <div className="text-[#8DC641] flex gap-1 mb-2">
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStarOutline />
            <IoIosStarOutline />
            </div>
            <div className="text-[12px] text-[#838383]">Last 6 month</div>
            </div>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 lg:col-span-4 bg-white shadow rounded-lg'>
          <div className='rounded-lg bg-card flex justify-between items-center p-3'>
            <div>
             <div className='flex items-center'>
             <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb3MNPgAEEjF2phJFIgyTwRBQFZmn9VNKVaA&s" alt="" className="w-10 h-10 rounded-full object-cover"/>
             <div className='ml-2'>
              <div className='flex items-center text-[15px] font-[500] leading-[16px]'>Bernard Murphy</div>
              <div className='text-[12px] text-[#838383]'>USA</div>
             </div>
             </div>
            </div>
            <div className='flex flex-col items-center'>
            <div className="text-[#8DC641] flex gap-1 mb-2">
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStarOutline />
            <IoIosStarOutline />
            </div>
            <div className="text-[12px] text-[#838383]">Last 6 month</div>
            </div>
          </div>
        </div>
        <div className='col-span-12 sm:col-span-6 lg:col-span-4 bg-white shadow rounded-lg'>
          <div className='rounded-lg bg-card flex justify-between items-center p-3'>
            <div>
             <div className='flex items-center'>
             <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRij6dtiHizH96qpCOe8WeXXP3yLyQJkPdGVg&s" alt="" className="w-10 h-10 rounded-full object-cover"/>
             <div className='ml-2'>
              <div className='flex items-center text-[15px] font-[500] leading-[16px]'>Norman Walters</div>
              <div className='text-[12px] text-[#838383]'>USA</div>
             </div>
             </div>
            </div>
            <div className='flex flex-col items-center'>
            <div className="text-[#8DC641] flex gap-1 mb-2">
            <IoIosStar />
            <IoIosStar />
            <IoIosStar />
            <IoIosStarOutline />
            <IoIosStarOutline />
            </div>
            <div className="text-[12px] text-[#838383]">Last 6 month</div>
            </div>
          </div>
        </div>
      </section>
      <section className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 bg-white shadow rounded-lg">
          <div className="px-6 py-5 font-semibold border-b border-gray-100">Chart</div>
          <div className="p-4 flex-grow h-[25rem]">
            <div className="flex items-center justify-center h-full px-4 py-16 text-gray-400 text-3xl font-semibold bg-gray-100 border-2 border-gray-200 border-dashed rounded-md">Chart</div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 bg-white shadow rounded-lg">
          <div className="flex items-center justify-between px-6 py-5 font-semibold border-b border-gray-100">
            <span>Lorem Ipsum is simply dummy text</span>
     
          </div>
          <div className='py-3'>
          <div className="overflow-y-auto max-h-[24rem]">
            <ul className="p-4 space-y-6">
              <li className="flex items-center">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Annette Watson profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold">9.3</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Calvin Steward profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold">8.9</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Ralph Richards profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold text-[14px]">8.7</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Bernard Murphy profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold text-[14px]">8.2</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Arlene Robertson profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold text-[14px]">8.2</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Jane Lane profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold text-[14px]">8.1</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Pat Mckinney profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold text-[14px]">7.9</span>
              </li>
              <li className="flex items-center !mt-3">
                <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                  <img src="https://static.vecteezy.com/system/resources/thumbnails/026/561/542/small_2x/check-in-location-icon-in-black-circle-png.png" alt="Norman Walters profile picture object-cover"/>
                </div>
                <span className="text-gray-600">Dummy Text</span>
                <span className="ml-auto font-semibold text-[14px]">7.7</span>
              </li>
            </ul>
          </div>
          </div>
        </div>
      </section>
    </main>
        </div>
    );
};
export default VenueHome;