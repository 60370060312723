import { useEffect, useState } from 'react';
import DesktopSidebar from './Sidebars/DesktopSidebar';
import MobileSidebar from './Sidebars/MobileSidebar';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getVenue } from '../../redux/actions/getVenue';
import VenueHome from '../VenueHome';
import { selectVenue } from '../../redux/reducers/generic/project';
import UserHome from '../UserHome';

export default function Home() {
    const dispatch = useDispatch();
    const user = useSelector((state)=>state.user)
    //create a state variable to track the height of the window
    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const venue = useSelector(selectVenue);
    const venue = user?.role === "venue" ? true : false

    //create an event handler that tracks the height of the window
    useEffect(() => {
        const resizeHandler = () => {
            setPageHeight(window.innerHeight);
            setPageWidth(window.innerWidth);
        };

        window.addEventListener('resize', resizeHandler);
        resizeHandler(); // Call it initially to set the initial width
        return () => {
            window.removeEventListener('resize', resizeHandler);
       
        };
    }, []);

    // useEffect(async() => {
    //     setLoading(true);
    //     await dispatch(getVenue())
    //     setLoading(false);
    // }, [])



    const height = pageHeight - (pageWidth < 1024 ? 56 : 0) + 'px';

    return (
        <>
            {/* <MobileSidebar /> */}
            <div
                className="bg-white w-full h-screen flex flex-row "
                style={{ maxHeight: height }}
            >
               <DesktopSidebar />
                <div
                    id="mainPage"
                    className={`w-full flex h-full overflow-auto lg:h-screen flex-col justify-center p-5`}
                >
                    <div className="w-full flex flex-col h-full">
                        {
                            venue ?
                                <VenueHome />
                             :
                            isLoading ?
                            <div> Loading...</div>
                            :
                            <UserHome />
                            
                        }
                        
                    </div>
                </div>
            </div>
        </>
    );
}
