import React from "react";
import { NavigationBar } from "../Landing/NavigationBar";
import Footer from "../Footer";
import Img1 from "../../assets/images/payment-img1.png"
import { NavbarDashboard } from "../Components/ApplicationUI/NavbarDashboard";

const PaymentC = () => {
    return (
        <>
            <NavbarDashboard />
            <div className="flex flex-col gap-[44px] py-[60px]">
                <img className="max-w-[426px] mx-auto w-full" src={Img1} alt="" />
                <div className="flex flex-col items-center gap-3">
                    <h3 className="font-poppins text-3xl font-medium leading-[36px] text-left">Tickets have been sent to</h3>
                    <p className="text-[#005AAB] font-poppins text-base font-normal leading-[30px] text-left">
                        Tahirhilbi324@gmail.com
                    </p>
                </div>
                <div className="flex flex-col items-center gap-3">
                    <p className="font-poppins text-base font-normal leading-[24px] text-left text-black">
                        Haven’t received tickets yet?
                    </p>
                    <div className="flex flex-col md:flex-row gap-[20px] my-[20px]">
                        <div className="flex flex-col gap-[16px]">
                            <button className="bg-[#005AAB] px-7 py-2 font-poppins text-[14px] font-semibold leading-[21px] text-left text-white">Pay Now</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default PaymentC;