import { Item } from './Item';

export const SidebarItems = () => {
    return (
        <>
            <div className=" py-1" />
            <Item />
        </>
    );
};
