import { useEffect, useState } from "react";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import Footer from "../Footer";
import { NavigationBar } from "../Landing/NavigationBar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Upload from "./Upload";
import { Spinner } from "../Components/Spinner";
import { createVenue } from "../../redux/actions/createVenue";
import {
    emailPasswordSignUp,
    doesEmailExist,
    getAuthorisationURLWithQueryParamsAndSetState,
    thirdPartySignInAndUp,
    redirectToThirdPartyLogin
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import environment from "../../environment";

import { IoCloseOutline, IoDocumentTextOutline, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import { toast } from "react-toastify";
import FormControl from "../../common/FormControl";
import SelectDropdown from "../../common/SelectDropdown";
import MultiSelectDropdown from "../../common/MultiSelectDropdown";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import DatePicker from "react-datepicker";
import loader from "../../methods/loader";

const ProviderSignUpForm = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [fileKey, setFileKey] = useState(null);
    const [formData, setFormData] = useState({
        locationCity: "",
        locationCitySection: "",
        locationState: "",
        business: "",
        businessName: "",
        category: "",
        hostOrVenue: "",
        website: "",
        instagram: "",
        facebook: "",
        tiktok: "",
        youtube: "",
        contactPhone: "",
        contactEmail: "",
        address: "",
        hours: "",
        bestTimeToVisit: "",
        multiLocations: "",
        hasFood: "",
        priceRange: "",
        password: "",
        confirmPassword: "",
        companyEIN: "", // Added companyEIN field
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        console.log(fileKey);
        setLoading(true);
        // if (!checked) {
        //     alert('Please accept the terms and conditions');
        //     setIsLoading(false);
        //     return;
        // }
        /// check email
        if (formData['password'] !== formData['confirmPassword']) {
            alert('Passwords do not match');
            setLoading(false);
            return;
        }

        if (formData['password'].length < 6) {
            alert('Your password must be at least 6 characters');
            setLoading(false);
            return;
        }

        try {
            checkEmail(formData['contactEmail']);
            let response = await emailPasswordSignUp({
                formFields: [
                    {
                        id: 'email',
                        value: formData['contactEmail']
                    },
                    {
                        id: 'password',
                        value: formData['password']
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
                setLoading(false);
            } else {
                // sign up successful. The session tokens are automatically handled by
                // the frontend SDK.
                setLoading(false);
                const { password, confirmPassword, ...formDataWithoutPasswords } = formData;
                formDataWithoutPasswords['fileKeyID'] = fileKey;
                dispatch(createVenue(formDataWithoutPasswords));
                navigate('/home');
            }
        } catch (err) {
            setLoading(false);
            console.log(err)
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
            } else {
                window.alert(err);
            }
        }

    };

    const checkEmail = async email => {
        try {
            let response = await doesEmailExist({
                email
            });

            if (response.doesExist) {
                window.alert('Email already exists. Please sign in instead');
            }
        } catch (err) {
            console.log(err)
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.

                window.alert(err.message);
            } else {
                window.alert(err.message);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="space-y-12 px-8 py-8">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Company Information</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        This information will be displayed publicly so be careful what you share.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="locationCity" className="block text-sm font-medium leading-6 text-gray-900">
                                Location City
                            </label>
                            <div className="mt-2">
                                <input
                                    id="locationCity"
                                    name="locationCity"
                                    type="text"
                                    placeholder="Enter Location City.."
                                    value={formData.locationCity}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="locationCitySection" className="block text-sm font-medium leading-6 text-gray-900">
                                Location City Section
                            </label>
                            <div className="mt-2">
                                <input
                                    id="locationCitySection"
                                    name="locationCitySection"
                                    type="text"
                                    placeholder="Enter Location City Section"
                                    value={formData.locationCitySection}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="locationState" className="block text-sm font-medium leading-6 text-gray-900">
                                Location State
                            </label>
                            <div className="mt-2">
                                <input
                                    id="locationState"
                                    name="locationState"
                                    type="text"
                                    placeholder="Enter Location State"
                                    value={formData.locationState}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="business" className="block text-sm font-medium leading-6 text-gray-900">
                                Business
                            </label>
                            <div className="mt-2">
                                <input
                                    id="business"
                                    name="business"
                                    type="text"
                                    placeholder="Enter Business"
                                    value={formData.business}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="businessName" className="block text-sm font-medium leading-6 text-gray-900">
                                Business Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="businessName"
                                    name="businessName"
                                    type="text"
                                    placeholder="Enter Business Name"
                                    value={formData.businessName}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
                                Category
                            </label>
                            <div className="mt-2">
                                <input
                                    id="category"
                                    name="category"
                                    type="text"
                                    placeholder="Enter Category"
                                    value={formData.category}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="hostOrVenue" className="block text-sm font-medium leading-6 text-gray-900">
                                Host or Venue
                            </label>
                            <div className="mt-2">
                                <input
                                    id="hostOrVenue"
                                    name="hostOrVenue"
                                    type="text"
                                    placeholder="Enter Host or Venue"
                                    value={formData.hostOrVenue}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                Website
                            </label>
                            <div className="mt-2">
                                <input
                                    id="website"
                                    name="website"
                                    type="text"
                                    placeholder="Enter Website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900">
                                Instagram
                            </label>
                            <div className="mt-2">
                                <input
                                    id="instagram"
                                    name="instagram"
                                    type="text"
                                    placeholder="Enter Instagram"
                                    value={formData.instagram}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="facebook" className="block text-sm font-medium leading-6 text-gray-900">
                                Facebook
                            </label>
                            <div className="mt-2">
                                <input
                                    id="facebook"
                                    name="facebook"
                                    type="text"
                                    placeholder="Enter Facebook"
                                    value={formData.facebook}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="tiktok" className="block text-sm font-medium leading-6 text-gray-900">
                                Tiktok
                            </label>
                            <div className="mt-2">
                                <input
                                    id="tiktok"
                                    name="tiktok"
                                    type="text"
                                    placeholder="Enter Tiktok"
                                    value={formData.tiktok}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="youtube" className="block text-sm font-medium leading-6 text-gray-900">
                                Youtube
                            </label>
                            <div className="mt-2">
                                <input
                                    id="youtube"
                                    name="youtube"
                                    type="text"
                                    placeholder="Enter Youtube"
                                    value={formData.youtube}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="contactPhone" className="block text-sm font-medium leading-6 text-gray-900">
                                Contact Phone
                            </label>
                            <div className="mt-2">
                                <input
                                    id="contactPhone"
                                    name="contactPhone"
                                    type="text"
                                    placeholder="Enter Contact Phone"
                                    value={formData.contactPhone}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="contactEmail" className="block text-sm font-medium leading-6 text-gray-900">
                                Contact Email
                            </label>
                            <div className="mt-2">
                                <input
                                    id="contactEmail"
                                    name="contactEmail"
                                    type="email"
                                    placeholder="Enter Contact Email"
                                    value={formData.contactEmail}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                Address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="address"
                                    name="address"
                                    type="text"
                                    placeholder="Enter Address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="hours" className="block text-sm font-medium leading-6 text-gray-900">
                                Hours
                            </label>
                            <div className="mt-2">
                                <input
                                    id="hours"
                                    name="hours"
                                    type="text"
                                    placeholder="Enter Hours"
                                    value={formData.hours}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="bestTimeToVisit" className="block text-sm font-medium leading-6 text-gray-900">
                                Best Time to Visit
                            </label>
                            <div className="mt-2">
                                <input
                                    id="bestTimeToVisit"
                                    name="bestTimeToVisit"
                                    type="text"
                                    placeholder="Enter Best Time to Visit"
                                    value={formData.bestTimeToVisit}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="multiLocations" className="block text-sm font-medium leading-6 text-gray-900">
                                Multi Locations
                            </label>
                            <div className="mt-2">
                                <input
                                    id="multiLocations"
                                    name="multiLocations"
                                    type="text"
                                    placeholder="Enter Multi Locations"
                                    value={formData.multiLocations}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="hasFood" className="block text-sm font-medium leading-6 text-gray-900">
                                Has Food (Y/N)
                            </label>
                            <div className="mt-2">
                                <input
                                    id="hasFood"
                                    name="hasFood"
                                    type="text"
                                    placeholder="Enter Y or N"
                                    value={formData.hasFood}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="priceRange" className="block text-sm font-medium leading-6 text-gray-900">
                                Price Range ($$)
                            </label>
                            <div className="mt-2">
                                <input
                                    id="priceRange"
                                    name="priceRange"
                                    type="text"
                                    placeholder="Enter Price Range"
                                    value={formData.priceRange}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="xxxxxxxxxx"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                                Confirm Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="xxxxxxxxxx"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="companyEIN" className="block text-sm font-medium leading-6 text-gray-900">
                                Company EIN
                            </label>
                            <div className="mt-2">
                                <input
                                    id="companyEIN"
                                    name="companyEIN"
                                    type="text"
                                    placeholder="Enter Company EIN"
                                    value={formData.companyEIN}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                Upload Government ID/Driver's License
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    <Upload setFileKey={setFileKey} isUploading={isUploading} setUploading={setUploading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 px-8 flex items-center justify-end gap-x-6 mb-8">

                <button
                    type="submit"
                    className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={isLoading}
                >
                    Create Account
                    {isLoading && <div className="ml-2 inline"><Spinner w='4' h='4' /></div>}
                </button>
            </div>
        </form>
    );
};

const ProviderSignUp = () => {
    const [form, setform] = useState({
        name: "",
        email: "",
        password: "",
        mobileNo: "",
        amenities: [],
        images: [],
        role: "venue",
        description: "",
        location: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        website_link: "",
        instagram_link: "",
        facebook_link: "",
        tiktok_link: "",
        youtube_link: "",
        applyAll: true,
        time_schedule: [
            { day: "monday", start_time: "", end_time: "", best_time_to_visit: "" },
            { day: "tuesday", start_time: "", end_time: "", best_time_to_visit: "" },
            { day: "wednesday", start_time: "", end_time: "", best_time_to_visit: "" },
            { day: "thursday", start_time: "", end_time: "", best_time_to_visit: "" },
            { day: "friday", start_time: "", end_time: "", best_time_to_visit: "" },
            { day: "saturday", start_time: "", end_time: "", best_time_to_visit: "" },
            { day: "sunday", start_time: "", end_time: "", best_time_to_visit: "" },
        ],
        foods: [{ item: "", price: "", image: "" }],
        drinks: [{ item: "", price: "", image: "" }],
        foodImages: "",
        company_ein: "",
        ein_image: "",
    });
    const history = useNavigate();
    const [amenities, setAmenities] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const inValidEmail = methodModel.emailvalidation(form?.email);
    const [eye, setEye] = useState({ password: false, confirmPassword: false });

    const foodFormatOptions = [
        { id: "manual", name: "Manual" },
        { id: "upload", name: "Upload" },
    ]

    useEffect(() => {
        getAmenities()
    }, [])

    const getAmenities = () => {
        ApiClient.get(`amenity/listing?status=active&sortBy=title asc`).then(res => {
            if (res.success) {
                setAmenities(res?.data?.map((item) => {
                    return ({ id: item?._id, name: item?.title })
                }))
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (!form?.ein_image) return
        let method = "post";
        let url = "users/register";
        let value = { ...form }
        loader(true)
        ApiClient.allApi(url, value, method).then((res) => {
            if (res.success) {
                setTimeout(() => {
                    toast.success("Registerd Successfully Please Check Your Email To Verify")
                }, 100);
                history(`/signin`);
            }
            loader(false)
        });
    }

    const ImagesUpload = (e, key) => {
        let files = Array.from(e.target.files);
        const acceptedTypes = ['image/jpeg', 'image/png']; // Add more image types if needed
        const filteredFiles = files.filter(file => acceptedTypes.includes(file.type));
        let invalidFiles = files.filter(file => !acceptedTypes.includes(file.type));
        if (invalidFiles.length > 0 && files?.length > 1) {
            toast.error('Some files are not valid format and will be ignored.Only JPG and PNG images are allowed.');
        }
        if (filteredFiles.length !== files.length && files?.length === 1) {
            toast.error('Only JPG and PNG images are allowed.');
        }
        if (filteredFiles?.length === 0) {
            return;
        }
        let images = form?.[key] || []
        loader(true)
        ApiClient.multiImageUpload('upload/multiple-images', filteredFiles, {}, "files").then(res => {
            if (res.success) {
                const data = res?.files?.map((item) => item?.fileName)
                images.push(...data)
                setform({ ...form, [key]: images })
            }
            loader(false)
        })
    }

    const handleImageDelete = (index, key) => {
        const data = [...form?.[key]]
        data.splice(index, 1)
        setform({ ...form, [key]: data })
    }

    const handleLocation = (place) => {
        const addressComponents = place?.address_components;
        const address = {};

        for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            const types = component.types;
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.long_name;
            }
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zipCode = component.long_name;
            }
        }

        address.location = place?.formatted_address
        address.coordinates = {
            type: "Point",
            coordinates: [place.geometry.location.lng(), place.geometry.location.lat()]
        }
        setform((prev) => ({ ...prev, ...address }))
    }

    const handleTimeSchedule = (date, index, key) => {
        let data = form?.time_schedule
        if (form?.applyAll) {
            data = data.map((item, i) => {
                return { ...item, [key]: date }
            })
        } else {
            data[index][key] = date
        }
        setform((prev) => ({ ...prev, time_schedule: data }))
    }

    const handleApplyAll = (checked) => {
        const data = form?.time_schedule[0]
        const timeSchedule = [
            { day: "monday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "tuesday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "wednesday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "thursday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "friday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "saturday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
            { day: "sunday", start_time: data?.start_time, end_time: data?.end_time, best_time_to_visit: data?.best_time_to_visit },
        ]
        if (checked) {
            setform((prev) => ({ ...prev, applyAll: checked, time_schedule: timeSchedule }))
        } else {
            setform((prev) => ({ ...prev, applyAll: checked }))
        }
    }

    const handleAddMoreInput = (e, index, key, object) => {
        let data = form?.[object]
        data[index][key] = e
        setform((prev) => ({ ...prev, [object]: data }))
    }
    const handleAddMore = (key) => {
        const data = [...form?.[key], { item: "", price: "", image: "" }]
        setform((prev) => ({ ...prev, [key]: data }))
    }
    const handleRemoveAddMore = (index, key) => {
        const data = form?.[key]?.filter((item, i) => i !== index)
        setform((prev) => ({ ...prev, [key]: data }))
    }

    const ImageUpload = (e, index, key, object) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        let data = form?.[object]
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                data[index][key] = res?.fileName
                setform((prev) => ({ ...prev, [object]: data }))
            }
            loader(false)
        })
    }

    const handleMenuImage = (index, key, object) => {
        let data = form?.[object]
        data[index][key] = ""
        setform((prev) => ({ ...prev, [object]: data }))
    }

    const uploadEIN = (e) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']; // Add more types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only doc and pdf are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/document', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, ein_image: res?.fileName })
            }
            loader(false)
        })
    }

    const venueProfileImageUpload = (e) => {
        let files = e.target.files
        let file = files.item(0)
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']; // Add more image types if needed
        if (!allowedTypes.includes(file.type)) {
            toast.error("Only JPG and PNG images are allowed.");
            return;
        }
        loader(true)
        ApiClient.postFormData('upload/image', { file: file }).then(res => {
            if (res.success) {
                setform({ ...form, image: res?.fileName })
            }
            loader(false)
        })
    }

    return (
        <>
            <section className="relative pb-4 px-4 sm:px-8 w-full bg-cover bg-[url('assets/images/providerbg.png')] h-[553px] flex flex-col items-center justify-center">
                <div className="w-full absolute top-0">
                    <NavigationBar />
                </div>
                <div className="text-center space-y-4">
                    <h1 className="flex font-montserrat flex-col text-white font-bold text-4xl md:text-5xl">
                        <span className="text-[#E1B524]">Unlock new opportunities for your business</span>
                        Sign Up as a Venue Today
                    </h1>
                    <p className="text-white font-montserrat max-w-xl mx-auto leading-relaxed">
                        Maximize your venue's potential with our comprehensive platform.
                    </p>
                </div>
            </section>
            {/* <ProviderSignUpForm /> */}

            <form onSubmit={handleSubmit} autoComplete="one-time-code">
                <div>
                    <div className="shadow-md rounded-md border-[1px] border-[#ededed] p-5 m-4 mb-4">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Venue Location</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="venue_name"
                                    label="Venue Name"
                                    placeholder="Enter Venue Name"
                                    autoComplete="one-time-code"
                                    value={form?.venue_name}
                                    onChange={(e) => setform({ ...form, venue_name: e })}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label>Location <span className="text-red-600">*</span></label>
                                <ReactGoogleAutocomplete
                                    apiKey={environment?.map_api_key}
                                    onPlaceSelected={(place) => { handleLocation(place) }}
                                    onChange={e => setform({ ...form, location: e.target.value })}
                                    value={form?.location}
                                    types={['(regions)']}
                                    key="venueLocation"
                                    placeholder="Enter Location"
                                    required
                                    className="bg-white w-full h-10 rounded-lg overflow-hidden px-2 mt-1 border border-[#00000036]"
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="country"
                                    label="Country"
                                    placeholder="Enter country"
                                    autoComplete="one-time-code"
                                    value={form?.country}
                                    onChange={(e) => setform({ ...form, country: e })}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="state"
                                    label="State"
                                    placeholder="Enter State"
                                    autoComplete="one-time-code"
                                    value={form?.state}
                                    onChange={(e) => setform({ ...form, state: e })}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="city"
                                    label="City"
                                    placeholder="Enter City"
                                    autoComplete="one-time-code"
                                    value={form?.city}
                                    onChange={(e) => setform({ ...form, city: e })}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="number"
                                    name="zipCode"
                                    label="Zip Code"
                                    placeholder="Enter Zip Code"
                                    autoComplete="one-time-code"
                                    value={form?.zipCode}
                                    onChange={(e) => setform({ ...form, zipCode: e })}
                                    maxlength="6"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="shadow-md rounded-md border-[1px] border-[#ededed] p-5 m-4 mb-4">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Contact Info</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="name"
                                    label="Name"
                                    placeholder="Enter Name"
                                    autoComplete="one-time-code"
                                    value={form?.name}
                                    onChange={(e) => setform({ ...form, name: e })}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="email"
                                    label="Email"
                                    placeholder="Enter Email"
                                    autoComplete="one-time-code"
                                    value={form.email}
                                    onChange={(e) => setform({ ...form, email: e })}
                                    required
                                />
                                {form?.email && !inValidEmail && submitted && (
                                    <div className="d-block text-red-600">Please enter valid email</div>
                                )}
                            </div>
                            <div className="mb-3 relative">
                                <FormControl
                                    type={eye?.password ? "text" : "password"}
                                    name="password"
                                    label="Password"
                                    placeholder="Enter Password"
                                    autoComplete="one-time-code"
                                    minlength="8"
                                    value={form?.password}
                                    onChange={(e) => setform({ ...form, password: e })}
                                    required
                                />
                                {!eye?.password ?
                                    <IoEyeOffOutline size={20} onClick={e => setEye({ ...eye, password: true })} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                    :
                                    <IoEyeOutline size={20} onClick={e => setEye({ ...eye, password: false })} className="absolute bottom-2.5 right-3 cursor-pointer" />
                                }
                            </div>
                            <div className="mobile_number mb-3">
                                <FormControl
                                    type="phone"
                                    name="mobileNo"
                                    autoComplete="one-time-code"
                                    label="Mobile Number"
                                    value={form?.mobileNo}
                                    onChange={(e) => setform({ ...form, mobileNo: e })}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Profile Image (JPG/PNG)</label>
                                <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                    {form?.image ? (
                                        <>
                                            <div className="flex flex-wrap gap-3 mt-3">
                                                <div className="relative">
                                                    <img src={methodModel.userImg(form?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                    <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, image: "" })} size={25} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[250px]`} style={{ gap: '8px' }}>
                                            <FiPlus />
                                            <input
                                                id="dropzone-file"
                                                type="file"
                                                className="hidden"
                                                onChange={venueProfileImageUpload}
                                            />
                                            Upload Profile Image
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow-md rounded-md border-[1px] border-[#ededed] p-5 m-4 mb-4">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Gallery</h2>
                        <div className="mb-3">
                            <label>Venue Images (JPG/PNG)</label>
                            <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px]`} style={{ gap: '8px' }}>
                                    <FiPlus />
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        className="hidden"
                                        multiple
                                        onChange={e => ImagesUpload(e, "images")}
                                    />
                                    Upload Images
                                </label>
                                {form?.images?.length > 0 ?
                                    <span className="flex flex-wrap gap-3">
                                        {form?.images?.map((item, index) => {
                                            return <div className="relative" key={index}>
                                                <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, 'images')} size={25} />
                                            </div>
                                        })}
                                    </span> : null
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <FormControl
                                type="editor"
                                name="description"
                                label="Description"
                                className="h-[200px] overflow-auto"
                                value={form?.description}
                                onChange={(e) => setform((prev) => ({ ...prev, description: e }))}
                            />
                        </div>
                    </div>
                    <div className="relative table-responsive shadow-md overflow-x-auto border border-[#eee] sm:rounded-lg bg-white m-4 p-5">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Time Schedule</h2>
                        <table className="table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <tr>
                                <th className="px-2 py-3 cursor-pointer whitespace-nowrap">
                                    <label className="flex items-cneter gap-2 cursor-pointer">
                                        <input type="checkbox" onChange={e => handleApplyAll(e.target.checked)} checked={form?.applyAll} className="h-5 w-5" />Apply for all
                                    </label>
                                </th>
                                <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Start Time <span className="text-red-600">*</span></th>
                                <th className="px-2 py-3 cursor-pointer whitespace-nowrap">End Time <span className="text-red-600">*</span></th>
                                <th className="px-2 py-3 cursor-pointer whitespace-nowrap">Best Time To Visit </th>
                            </tr>
                            {form?.time_schedule?.map((item, index) => {
                                return <tr key={index}>
                                    <td className="px-2 py-4 whitespace-nowrap undefined capitalize">{item?.day}</td>
                                    <td className="px-2 py-4 whitespace-nowrap undefined">
                                        <DatePicker
                                            selected={item?.start_time}
                                            onChange={(date) => handleTimeSchedule(date, index, 'start_time')}
                                            className={`border border-[#00000036] rounded-md h-8 p-2 ${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                            dateFormat="hh:mm a"
                                            placeholderText="Start Time"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Start Time"
                                            disabled={index !== 0 && form?.applyAll}
                                            required
                                        />
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap undefined">
                                        <DatePicker
                                            selected={item?.end_time}
                                            onChange={(date) => handleTimeSchedule(date, index, 'end_time')}
                                            className={`border border-[#00000036] rounded-md h-8 p-2 ${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                            dateFormat="hh:mm a"
                                            placeholderText="End Time"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="End Time"
                                            disabled={index !== 0 && form?.applyAll}
                                            required
                                        />
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap undefined">
                                        <DatePicker
                                            selected={item?.best_time_to_visit}
                                            onChange={(date) => handleTimeSchedule(date, index, 'best_time_to_visit')}
                                            className={`border border-[#00000036] rounded-md h-8 p-2 ${index !== 0 && form?.applyAll && "cursor-not-allowed"}`}
                                            dateFormat="hh:mm a"
                                            placeholderText="Best Time To Visit"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Best Time"
                                            disabled={index !== 0 && form?.applyAll}
                                        />
                                    </td>
                                </tr>
                            })}
                        </table>
                    </div>
                    <div className="shadow-md rounded-md border-[1px] border-[#ededed] p-5 m-4 mb-4">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Social Media</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="website_link"
                                    label="Website Link"
                                    placeholder="Enter Website Link"
                                    autoComplete="one-time-code"
                                    value={form?.website_link}
                                    onChange={(e) => setform({ ...form, website_link: e })}
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="instagram_link"
                                    label="Instagram Link"
                                    placeholder="Enter Instagram Link"
                                    autoComplete="one-time-code"
                                    value={form?.instagram_link}
                                    onChange={(e) => setform({ ...form, instagram_link: e })}
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="facebook_link"
                                    label="Facebook Link"
                                    placeholder="Enter Facebook Link"
                                    autoComplete="one-time-code"
                                    value={form?.facebook_link}
                                    onChange={(e) => setform({ ...form, facebook_link: e })}
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="tiktok_link"
                                    label="Tiktok Link"
                                    placeholder="Enter Tiktok Link"
                                    autoComplete="one-time-code"
                                    value={form?.tiktok_link}
                                    onChange={(e) => setform({ ...form, tiktok_link: e })}
                                />
                            </div>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="youtube_link"
                                    label="Youtube Link"
                                    placeholder="Enter Youtube Link"
                                    autoComplete="one-time-code"
                                    value={form?.youtube_link}
                                    onChange={(e) => setform({ ...form, youtube_link: e })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="shadow-md rounded-md border-[1px] border-[#ededed] p-5 m-4 mb-4">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Menu Items & Amenities</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="mb-3">
                                <label>Amenities <span className="text-red-600">*</span></label>
                                <MultiSelectDropdown
                                    id="statusDropdown"
                                    placeholder="Select Amenities"
                                    intialValue={form?.amenities}
                                    className="mt-1 capitalize"
                                    result={(e) => setform({ ...form, amenities: e?.value })}
                                    options={amenities}
                                    required={true}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Menu Items Format <span className="text-red-600">*</span></label>
                                <SelectDropdown
                                    id="statusDropdown"
                                    displayValue="name"
                                    className="mt-1"
                                    placeholder="Select Choose Format"
                                    theme="search"
                                    intialValue={form?.menu_item_format}
                                    result={(e) => setform({ ...form, menu_item_format: e?.value })}
                                    options={foodFormatOptions}
                                    isClearable={false}
                                    required
                                />
                            </div>
                        </div>
                        {form?.menu_item_format === "manual" &&
                            <>
                                <div className="mb-3">
                                    <label>Foods</label>
                                    <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-12">
                                            {form?.foods?.map((itm, index) => {
                                                return <div key={index} className="flex mb-3">
                                                    <FormControl
                                                        type="text"
                                                        name="foods"
                                                        placeholder="Enter Item"
                                                        autoComplete="one-time-code"
                                                        value={itm?.item}
                                                        onChange={(e) => handleAddMoreInput(e, index, "item", "foods")}
                                                        required
                                                    />
                                                    <FormControl
                                                        type="number"
                                                        name="price"
                                                        placeholder="Enter Price"
                                                        autoComplete="one-time-code"
                                                        className="mx-3"
                                                        value={itm?.price}
                                                        onChange={(e) => handleAddMoreInput(e, index, "price", "foods")}
                                                        maxlength={10}
                                                        required
                                                    />
                                                    <div className="flex flex-col rounded-lg cursor-pointer w-[80%] gap-6 max-sm:mx-auto ml-6">
                                                        {itm?.image ? (
                                                            <>
                                                                <div className="relative flex flex-wrap gap-3 mt-3">
                                                                    <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                                    <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "foods")} size={25} />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                <FiPlus />
                                                                <input
                                                                    id="dropzone-file"
                                                                    type="file"
                                                                    className="hidden"
                                                                    onChange={e => ImageUpload(e, index, "image", "foods")}
                                                                />
                                                                Upload Image
                                                            </label>
                                                        )}
                                                    </div>
                                                    {form?.foods?.length > 1 &&
                                                        <div className="ml-3 bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "foods")}><GrSubtractCircle size={20} /></div>
                                                    }
                                                </div>
                                            })}
                                        </div>
                                        <div className="col-span-12 flex justify-end">
                                            <div className="bg-[#003b85] p-3 text-white rounded-lg cursor-pointer w-fit ml-3" onClick={e => handleAddMore("foods")}>
                                                <IoMdAddCircleOutline size={20} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label>Drinks</label>
                                    <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-12">
                                            {form?.drinks?.map((itm, index) => {
                                                return <div key={index} className="flex mb-3">
                                                    <FormControl
                                                        type="text"
                                                        name="drinks"
                                                        placeholder="Enter Item"
                                                        autoComplete="one-time-code"
                                                        value={itm?.item}
                                                        onChange={(e) => handleAddMoreInput(e, index, "item", "drinks")}
                                                        required
                                                    />
                                                    <FormControl
                                                        type="number"
                                                        name="price"
                                                        placeholder="Enter Price"
                                                        autoComplete="one-time-code"
                                                        className="mx-3"
                                                        value={itm?.price}
                                                        onChange={(e) => handleAddMoreInput(e, index, "price", "drinks")}
                                                        maxlength={10}
                                                        required
                                                    />
                                                    <div className="flex flex-col rounded-lg cursor-pointer w-[80%] gap-6 max-sm:mx-auto ml-6">
                                                        {itm?.image ? (
                                                            <>
                                                                <div className="relative flex flex-wrap gap-3 mt-3">
                                                                    <img src={methodModel.userImg(itm?.image)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain ml-auto" />
                                                                    <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleMenuImage(index, "image", "drinks")} size={25} />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] h-10 focus:outline-none font-medium rounded-lg text-sm px-5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                                <FiPlus />
                                                                <input
                                                                    id="dropzone-file"
                                                                    type="file"
                                                                    className="hidden"
                                                                    onChange={e => ImageUpload(e, index, "image", "drinks")}
                                                                />
                                                                Upload Image
                                                            </label>
                                                        )}
                                                    </div>
                                                    {form?.drinks?.length > 1 &&
                                                        <div className="ml-3 bg-red-600 p-3 text-white rounded-lg cursor-pointer h-fit" onClick={e => handleRemoveAddMore(index, "drinks")}><GrSubtractCircle size={20} /></div>
                                                    }
                                                </div>
                                            })}
                                        </div>
                                        <div className="col-span-12 flex justify-end">
                                            <div className="bg-[#003b85] p-3 text-white rounded-lg cursor-pointer w-fit ml-3" onClick={e => handleAddMore("drinks")}>
                                                <IoMdAddCircleOutline size={20} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {form?.menu_item_format === "upload" &&
                            <>
                                <label>Upload Food Images (JPG/PNG)</label>
                                <div className="flex flex-col rounded-lg cursor-pointer gap-6 max-sm:mx-auto">
                                    <label className={`flex items-center justify-center cursor-pointer text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200 sm:max-w-[200px] `} style={{ gap: '8px' }}>
                                        <FiPlus />
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            className="hidden"
                                            multiple
                                            onChange={e => ImagesUpload(e, 'foodImages')}
                                        />
                                        Upload Images
                                    </label>
                                    {form?.foodImages?.length > 0 ?
                                        <span className="flex flex-wrap gap-3">
                                            {form?.foodImages?.map((item, index) => {
                                                return <div className="relative" key={index}>
                                                    <img src={methodModel.userImg(item)} className="bg-white thumbnail !w-[100px] !h-[100px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                                    <IoCloseOutline className="absolute -top-2 -right-2 pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => handleImageDelete(index, "foodImages")} size={25} />
                                                </div>
                                            })}
                                        </span> : null
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className="shadow-md rounded-md border-[1px] border-[#ededed] p-5 m-4 mb-4">
                        <h2 className="text-[20px] font-[600] text-[#111827] mb-2">Documents</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    name="company_ein"
                                    label="Company EIN"
                                    placeholder="Enter Company EIN"
                                    autoComplete="one-time-code"
                                    value={form?.company_ein}
                                    onChange={(e) => setform({ ...form, company_ein: e })}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                                    Upload Company EIN <span className="text-red-600">*</span>
                                </label>
                                {form?.ein_image ?
                                    <div className="relative">
                                        <IoDocumentTextOutline size={20} className="bg-white thumbnail !w-[60px] !h-[60px] rounded-lg shadow-lg border-[2px] border-white object-contain" />
                                        <IoCloseOutline className="absolute -top-2 left-[60px] cursor-pointer hover:text-red-600 w-5 h-5 border bg-white shadow-md rounded-[50%]" onClick={e => setform({ ...form, ein_image: "" })} size={25} />
                                    </div>
                                    :
                                    <label className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 cursor-pointer">
                                        <div className="text-center">
                                            <div className={`flex items-center justify-center text-black-800 bg-[#fff] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 border-2 border-dashed border-gray-200`} style={{ gap: '8px' }}>
                                                <FiPlus />
                                                <input
                                                    id="dropzone-file"
                                                    type="file"
                                                    className="hidden"
                                                    multiple
                                                    onChange={e => uploadEIN(e)}
                                                />
                                                Upload EIN
                                            </div>
                                        </div>
                                    </label>
                                }
                                {!form?.ein_image && submitted &&
                                    <span className="text-red-600">Company EIN is required</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-right m-4">
                        <button type="submit" className="text-white bg-[#005AAB] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                            Register
                        </button>
                    </div>
                </div>
            </form >
            <Footer />
        </>
    );
};

export default ProviderSignUp;