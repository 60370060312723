import React, { useState } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import BackIcon from '../../assets/images/back.png';
import EditEvent from './editEvent';
import BannerEvent from './BannerEvent';
import TicketingEvent from './Ticketing';
import ReveiwEvent from './reviewEvent';
import { NavbarDashboard } from '../Components/ApplicationUI/NavbarDashboard';

const steps = ['Edit', 'Banner', 'Ticketing', 'Review'];

const CreateEvent = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <>
            <NavbarDashboard />
            <div className="w-[85%] mx-auto flex items-center gap-7 py-[30px]">
                <button>
                    <img className="w-[46px] h-[46px]" src={BackIcon} alt="" />
                </button>
                <h2 className="font-montserrat text-[28px] font-semibold leading-[34.13px] text-left text-black">
                    Create Event
                </h2>
            </div>
            <div className="w-[85%] mx-auto py-[40px]">
                <div className="relative flex justify-between items-center">
                    {steps.map((step, index) => (
                        <div key={index} className="flex-1 relative">
                            <div
                                className={`flex items-center justify-center rounded-full transition-colors duration-300 ${
                                    index <= currentStep
                                        ? 'bg-white text-[#053967]'
                                        : 'text-gray-500'
                                }`}
                                onClick={() => setCurrentStep(index)}
                            >
                                <span
                                    className={`h-2.5 w-2.5 rounded-full ${
                                        index <= currentStep
                                            ? 'bg-[#053967]'
                                            : 'bg-[#6F6F6F66]'
                                    }`}
                                >
                                    <span className="h-[5px] w-[5px] rounded-full bg-white"></span>
                                </span>
                            </div>
                            {index <= steps.length - 1 && (
                                <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-0.5">
                                    <div
                                        className={`h-full ${index <= currentStep ? 'bg-[#053967]' : 'bg-[#6F6F6F66]'}`}
                                    ></div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="relative flex justify-between items-center mb-8">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className="flex-1 relative text-center"
                        >
                            <div
                                className={`cursor-pointer transition-colors duration-300 font-bold ${
                                    index <= currentStep
                                        ? 'text-[#053967]'
                                        : 'text-[#6F6F6F66]'
                                }`}
                                onClick={() => setCurrentStep(index)}
                            >
                                {step}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="p-4">
                    {currentStep === 0 ? (
                        <EditEvent />
                    ) : currentStep === 1 ? (
                        <BannerEvent />
                    ) : currentStep === 2 ? (
                        <TicketingEvent />
                    ) : currentStep === 3 ? (
                        <ReveiwEvent />
                    ) : null}
                    <div className="mt-4 flex justify-between">
                        <button
                            onClick={handleBack}
                            disabled={currentStep === 0}
                            className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-30 disabled:cursor-not-allowed"
                        >
                            Back
                        </button>
                        <button
                            onClick={handleNext}
                            className="bg-[#005AAB] text-white py-2 px-4 rounded"
                        >
                            {currentStep === steps.length - 1
                                ? 'Publish Event'
                                : 'Save And Continue'}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default CreateEvent;
