import { gql } from '@apollo/client';
export const GET_SIGNED_UPLOAD_URL = gql`
    mutation GetSignedUploadURL($filename: String!, $isLogo: Boolean) {
        getSignedUploadURL(filename: $filename, isLogo: $isLogo)
    }
`;

export const GET_SIGNED_DOWNLOAD_URL = gql`
    mutation GetSignedDownloadURL($filename: String!) {
        getSignedDownloadURL(filename: $filename)
    }
`;


export const CREATE_VENUE = gql`
    mutation CreateVenue($details: VenueInput!) {
        createVenue(details: $details)
    }
`;

export const GET_VENUE = gql`
    query GetVenue {
        getVenue {
        _id
        created
        locationCity
        locationCitySection
        locationState
        business
        businessName
        category
        hostOrVenue
        website
        instagram
        facebook
        tiktok
        youtube
        contactPhone
        contactEmail
        address
        hours
        bestTimeToVisit
        multiLocations
        hasFood
        priceRange
        companyEIN
        fileKeyID
        }
    }
`;

export const UPDATE_VENUE = gql`
    mutation UpdateVenue($details: VenueInput!) {
        updateVenue(details: $details)
    }
`;


export const GET_VENUES = gql`
    query GetVenues {
        getVenues {
        _id
        created
        locationCity
        locationCitySection
        locationState
        business
        businessName
        category
        hostOrVenue
        website
        instagram
        facebook
        tiktok
        youtube
        contactPhone
        contactEmail
        address
        hours
        bestTimeToVisit
        multiLocations
        hasFood
        priceRange
        companyEIN
        fileKeyID
        }
    }
`;