import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import logo from "../../assets/images/logo.svg"
import methodModel from '../../methods/methods';
import FormControl from '../../common/FormControl';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';

export const Forgot = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const inValidEmail = methodModel.emailvalidation(email);
    const [submitted, setSubmitted] = useState(false)
    const role = methodModel.getPrams("role")

    useEffect(()=>{
        if (localStorage.getItem("token")) {
            navigate("/");
        }
    },[])

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!inValidEmail) return
        let url = "users/forgot/password"
        ApiClient.put(url,{ email }).then((res)=>{
            if(res.success){
                setTimeout(() => {
                    toast.success(res?.message)
                }, 100);
                if(role){
                    navigate(`/signin?role=${role}`)
                }else{
                    navigate(`/signin`)
                }
            }
        })
    }

    return (
        <div className="light">
            <section className="pt-8 pb-8 bg-gray-50 dark:bg-gray-900 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="ml-4 mt-4">
                            <div onClick={() => { navigate(-1) }}>
                                <ArrowLeftIcon className="cursor-pointer" width={20} />
                            </div>
                        </div>
                        <div className="px-4 space-y-4 md:space-y-6 pb-4">
                            <Link to="/" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                <img className="h-8 rounded p-1 mr-2" src={logo} alt="logo" />
                            </Link>
                            <h2 className="text-center text-xl font-semibold tracking-tight text-gray-600 dark:text-gray-400">
                                Forgot your password
                            </h2>
                            <form onSubmit={handleSubmit} className="items-center space-y-4 md:space-y-6">
                                <div>
                                    <FormControl
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email"
                                        autoComplete="one-time-code"
                                        value={email}
                                        onChange={(e) => setEmail(e)}
                                        required
                                    />
                                    {email && !inValidEmail && submitted && (
                                        <div className="d-block text-red-600">Please enter valid email</div>
                                    )}
                                </div>
                                <button type="submit" className={`w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}>
                                    Forgot Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
