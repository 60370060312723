import React from 'react';
import img1 from '../../assets/images/Free-Event.png';
import img2 from '../../assets/images/Ticketed-Event.png';
const TicketingEvent = () => {
    return (
        <>
            <p className="font-medium text-[24px] leading-[29.26px] text-left text-[#2D2C3C] font-montserrat mb-[30px]">
                What type of event are you running?
            </p>
            <div className="flex gap-8 mb-[30px]">
                <div>
                    <img src={img2} alt="" />
                </div>
                <div>
                    <img src={img1} alt="" />
                </div>
            </div>
            <p className="font-medium text-[24px] leading-[29.26px] text-left text-[#2D2C3C] font-montserrat mb-[30px]">
                What tickets are you selling?
            </p>
            <div className="flex items-center gap-8">
                <div className="w-full flex flex-col gap-2">
                    <label
                        htmlFor=""
                        className="font-semibold text-[20px] leading-[27.24px] text-left text-[#2D2C3C] font-open-sans"
                    >
                        Ticket Name
                    </label>
                    <input
                        type="email"
                        id="helper-text"
                        aria-describedby="helper-text-explanation"
                        class="h-[50px] bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Enter the name of your event"
                    />
                </div>
                <div className="w-full">
                    <label class="flex items-center gap-[5px] mb-2 font-semibold text-[20px] leading-[27.24px] text-left text-[#2D2C3C] font-open-sans">
                        Ticket Price
                    </label>
                    <div class="flex">
                        <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                            <svg
                                width="15"
                                height="25"
                                viewBox="0 0 15 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.58789 24.7432V21.667C5.46484 21.6475 4.39062 21.5498 3.36523 21.374C2.34961 21.1982 1.51953 20.9688 0.875 20.6855V18.3125C1.54883 18.625 2.41309 18.8984 3.46777 19.1328C4.52246 19.3672 5.5625 19.4941 6.58789 19.5137V13.0684C5.29883 12.707 4.22949 12.2969 3.37988 11.8379C2.54004 11.3691 1.91016 10.7979 1.49023 10.124C1.07031 9.4502 0.860352 8.63477 0.860352 7.67773C0.860352 6.68164 1.09473 5.83203 1.56348 5.12891C2.04199 4.41602 2.71094 3.85937 3.57031 3.45898C4.42969 3.05859 5.43555 2.8291 6.58789 2.77051V0.236328H8.375V2.74121C9.41992 2.77051 10.3721 2.8877 11.2314 3.09277C12.0908 3.28809 12.8867 3.54199 13.6191 3.85449L12.8574 5.89062C12.1934 5.62695 11.4756 5.40234 10.7041 5.2168C9.93262 5.03125 9.15625 4.9043 8.375 4.83594V11.2373C9.68359 11.6182 10.7773 12.0234 11.6562 12.4531C12.5449 12.8828 13.2139 13.415 13.6631 14.0498C14.1123 14.6846 14.3369 15.5049 14.3369 16.5107C14.3369 17.9365 13.8145 19.0889 12.7695 19.9678C11.7246 20.8369 10.2598 21.3643 8.375 21.5498V24.7432H6.58789ZM8.375 19.3818C9.57617 19.2646 10.4697 18.9766 11.0557 18.5176C11.6514 18.0488 11.9492 17.4336 11.9492 16.6719C11.9492 16.125 11.832 15.6758 11.5977 15.3242C11.373 14.9727 11.002 14.6699 10.4844 14.416C9.9668 14.1523 9.26367 13.9033 8.375 13.6689V19.3818ZM6.58789 10.6221V4.89453C5.85547 4.94336 5.24023 5.08008 4.74219 5.30469C4.24414 5.5293 3.86816 5.82227 3.61426 6.18359C3.37012 6.54492 3.24805 6.97461 3.24805 7.47266C3.24805 8.04883 3.35547 8.53223 3.57031 8.92285C3.79492 9.30371 4.15137 9.63086 4.63965 9.9043C5.1377 10.168 5.78711 10.4072 6.58789 10.6221Z"
                                    fill="#5A5A5A"
                                />
                            </svg>
                        </span>
                        <input
                            type="text"
                            id="website-admin"
                            class="rounded-none rounded-e-lg bg-white h-[50px] border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="00.00"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default TicketingEvent;
