import React, { useState } from "react";
import AboutImg1 from "../../../assets/images/about-img.png";
import ServiceImg from "../../../assets/images/service-img.png";
import Map from "../../../assets/images/map.png";
import icon1 from "../../../assets/images/Icons/serv-icon1.svg";
import icon2 from "../../../assets/images/Icons/serv-icon2.svg";
import icon3 from "../../../assets/images/Icons/serv-icon3.svg";
import icon4 from "../../../assets/images/Icons/serv-icon4.svg";
import left from "../../../assets/images/Icons/arrow-left.svg";
import right from "../../../assets/images/Icons/arrow-right.svg";
import user from "../../../assets/images/Icons/user2.svg";
import mail from "../../../assets/images/Icons/mail.svg";
import img1 from "../../../assets/images/g-img1.png";
import img2 from "../../../assets/images/g-img2.png";
import img3 from "../../../assets/images/g-img3.png";
import img4 from "../../../assets/images/g-img4.png";
import img5 from "../../../assets/images/g-img5.png";
import img6 from "../../../assets/images/g-img6.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
// Testimonals
import person1 from "../../../assets/images/person1.svg"
import comma from "../../../assets/images/cooma.svg"
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import { NavigationBar } from "../../Landing/NavigationBar";
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { IoIosCloseCircleOutline } from "react-icons/io";


const Landing2 = () => {
    const swiperRef = React.useRef(null)
    const navigate = useNavigate()
    let [isOpen, setIsOpen] = useState(false)

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }
    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }

    const handleRedirection = (role) => {
        setIsOpen(false)
        if (role === "venue") {
            navigate(`/providersignup`)
        } else if (role === "host") {
            navigate(`/auth?role=${role}`)
        } else {
            return
        }
    }

    return (
        <>
            {/* header section */}
            <section className="relative pb-4 px-4 sm:px-8 w-full bg-cover bg-[url('assets/images/headerbg2.png')] h-[700px] flex flex-col items-center justify-center">
                <div className="w-full absolute top-0">
                    <NavigationBar />
                </div>
                <div className="text-center space-y-4">
                    <h1 className="flex font-montserrat flex-col text-white font-bold text-4xl md:text-5xl">
                        <span className="text-[#E1B524]"> Grow Your Business </span>
                        with MARC
                    </h1>
                    <p className="text-white font-montserrat max-w-xl mx-auto leading-relaxed">
                        Find Customers Near You
                    </p>
                </div>
                <div className="mt-6 font-poppins justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                    <button type="button" onClick={() => setIsOpen(true)} className="px-3 py-2 w-full bg-[#005AAB] text-white text-center rounded-full shadow-md block sm:w-auto text-[18px] text-nowrap font-[500]">
                        Create Account
                    </button>
                </div>
            </section>

            {/* About section  */}
            <section className="bg-[#D9D9D938]">
                <div className="max-w-screen-xl mx-auto py-10 md:px-8">
                    <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">

                        <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
                            <h3 className="text-[28px] font-[600]">
                                Why Marc
                            </h3>

                            <p className="mt-3font-[400]">
                                m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet consectetur adipiscing elit Ut et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et massam ipsum dolor sit amet t et massa m ipsum dolor sit amet consectetur adipiscing elit Ut et massa
                            </p>
                            <button type="button" onClick={() => setIsOpen(true)} className="text-[18px] text-[#FFFFFF] font-[600] bg-[#005AAB] rounded-[25.42px] py-2 px-3">Create Account</button>
                        </div>
                        <div className="flex-1 mt-4 sm:mt-0
                         sm:hidden lg:block flex justify-center items-center">
                            <img src={AboutImg1} className="md:max-w-lg sm:rounded-lg" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            {/* services section */}
            <section className="bg-[#D9D9D938]">
                <div className="max-w-screen-xl mx-auto py-10 md:px-8">
                    <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
                        <div className="flex-1 mt-4 sm:mt-0
                         sm:hidden lg:block flex justify-center items-center">
                            <img src={ServiceImg} className="md:max-w-lg sm:rounded-lg h-full md:h-[522px] object-contain" alt="" />
                        </div>
                        <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
                            <h3 className="text-[28px] font-[600]">
                                Services Marc Provides
                            </h3>

                            <p className="mt-3font-[400] mt-[20px]">
                                m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet
                                massa m ipsum dolor sit amet consectetur adipiscing m ipsum dolor sit amet consectetur adipiscing elit Ut et mm ipsum dolor sit amet consectetur adipiscing elit Ut et massaassam ipsum dolor sit amet
                                massa m ipsum dolor sit amet consectetur adipiscing
                            </p>
                            <ul className="flex flex-col gap-4 py-[20px]">

                                <li className="flex gap-2">
                                    <img className="w-[49px] h-[40px]" src={icon1} alt="" />
                                    <span>Booking & Scheduling</span>
                                </li>
                                <li className="flex gap-2">
                                    <img className="w-[49px] h-[40px]" src={icon2} alt="" />
                                    <span>Communication Channel</span>
                                </li>
                                <li className="flex gap-2">
                                    <img className="w-[49px] h-[40px]" src={icon3} alt="" />
                                    <span>Management</span>
                                </li>
                                <li className="flex gap-2">
                                    <img className="w-[49px] h-[40px]" src={icon4} alt="" />
                                    <span>Booking & Scheduling</span>
                                </li>
                            </ul>

                            <button className="text-[18px] text-[#FFFFFF] font-[600] bg-[#005AAB] rounded-[25.42px] py-2 px-3">List Your Services</button>
                        </div>

                    </div>
                </div>
            </section>

            {/* Testimonals */}
            <section className='mt-10 box-border'>
                <div className='ps-0 md:ps-28 mx-auto '>
                    <div className='flex flex-col lg:flex-row gap-5 lg:gap-[52px]'>
                        <div className='px-4 px-md-0 py-10 md:py-[113px] max-w-[395px] w-full relative testimonals-swiper'>
                            <h5 className='text-[24px] font-[600] text-[#E1B524]'>Testimonals</h5>
                            <p className='text-[38px] font-[500] leading-[50px] text-[#000000] mt-4'>
                                Testimonial
                                Don't Believe Me Check What Client Think Of Us
                            </p>
                            <div className='flex items-center mt-10'>
                                <div className="relative">
                                    <button className='swiper-button-next after:hidden' onClick={handleNext}>
                                        <img src={left} alt="" />
                                    </button>
                                    <button className='swiper-button-prev after:hidden' onClick={handlePrev}>

                                        <img src={right} alt="" />
                                    </button>
                                </div>
                                <p className='text-[18px] text-[#444444] font-[400] ps-20'>There are many variations</p>
                            </div>
                        </div>
                        <div className='flex-1 px-4 px-md-0 bg-[#F7F7F7] w-full lg:w-1/3 ps-4 md:ps-16 '>
                            <Swiper
                                loop={true}
                                slidesPerView={1}
                                spaceBetween={10}
                                breakpoints={{
                                    1100: {
                                        slidesPerView: 2.2,
                                        spaceBetween: 40
                                    }
                                }}

                                navigation={false}
                                modules={[Navigation]}
                                ref={swiperRef}
                                className="mySwiper pt-24 pb-16  max-w-[900px] w-full ">
                                <SwiperSlide>
                                    <div className="pb-[49px] relative w-full h-fit px-6 shadow-lg bg-white rounded-[10px]">
                                        <div className='flex flex-col justify-center items-center'>
                                            <img className='absolute -top-[60px] z-20 !w-[129px] !h-[129px] object-contain' src={person1} alt="" />
                                        </div>
                                        <div className='flex flex-col justify-center items-center  pt-16'>
                                            <img className=' !h-[18px] object-contain' src={comma} alt="" />
                                            <h3 className='text-[24px] font-[600] text-[#E1B524] pt-4'>David Werner</h3>
                                            <p className='text-[16px] font-[400] text-[#444444] leading-[37px]'>hsdjhabdjhasvdjhsavd</p>
                                            <p className='text-center pt-6 text-[18px] font-[400] leading-[37px] text-[#444444]'>
                                                Sukh Cha'n Wellness Club is a pioneering wellness center that offers a unique and holistic a
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="pb-[49px] relative w-full h-fit px-6 shadow-lg bg-white rounded-[10px]">
                                        <div className='flex flex-col justify-center items-center'>
                                            <img className='absolute -top-[60px] z-20 !w-[129px] !h-[129px] object-contain' src={person1} alt="" />
                                        </div>
                                        <div className='flex flex-col justify-center items-center  pt-16'>
                                            <img className=' !h-[18px] object-contain' src={comma} alt="" />
                                            <h3 className='text-[24px] font-[600] text-[#E1B524] pt-4'>David Werner</h3>
                                            <p className='text-[16px] font-[400] text-[#444444] leading-[37px]'>hsdjhabdjhasvdjhsavd</p>
                                            <p className='text-center pt-6 text-[18px] font-[400] leading-[37px] text-[#444444]'>
                                                Sukh Cha'n Wellness Club is a pioneering wellness center that offers a unique and holistic a
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="pb-[49px] relative w-full h-fit px-6 shadow-lg bg-white rounded-[10px]">
                                        <div className='flex flex-col justify-center items-center'>
                                            <img className='absolute -top-[60px] z-20 !w-[129px] !h-[129px] object-contain' src={person1} alt="" />
                                        </div>
                                        <div className='flex flex-col justify-center items-center  pt-16'>
                                            <img className=' !h-[18px] object-contain' src={comma} alt="" />
                                            <h3 className='text-[24px] font-[600] text-[#E1B524] pt-4'>David Werner</h3>
                                            <p className='text-[16px] font-[400] text-[#444444] leading-[37px]'>hsdjhabdjhasvdjhsavd</p>
                                            <p className='text-center pt-6 text-[18px] font-[400] leading-[37px] text-[#444444]'>
                                                Sukh Cha'n Wellness Club is a pioneering wellness center that offers a unique and holistic a
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="pb-[49px] relative w-full h-fit px-6 shadow-lg bg-white rounded-[10px]">
                                        <div className='flex flex-col justify-center items-center'>
                                            <img className='absolute -top-[60px] z-20 !w-[129px] !h-[129px] object-contain' src={person1} alt="" />
                                        </div>
                                        <div className='flex flex-col justify-center items-center  pt-16'>
                                            <img className=' !h-[18px] object-contain' src={comma} alt="" />
                                            <h3 className='text-[24px] font-[600] text-[#E1B524] pt-4'>David Werner</h3>
                                            <p className='text-[16px] font-[400] text-[#444444] leading-[37px]'>hsdjhabdjhasvdjhsavd</p>
                                            <p className='text-center pt-6 text-[18px] font-[400] leading-[37px] text-[#444444]'>
                                                Sukh Cha'n Wellness Club is a pioneering wellness center that offers a unique and holistic a
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>

                        </div>
                    </div>
                </div>
            </section>

            {/* contact section */}
            <div className="py-10">
                <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                    <div className="w-[93%] mx-auto gap-12 justify-between flex flex-col ">

                        <div className="flex flex-col md:flex-row gap-[29px]">
                            <div className="w-full">
                                <h3 className="text-[#E1B524] text-[16px] md:text-[24px] font-semibold">
                                    Contact
                                </h3>
                                <h2 className="text-[44px] font-[500] font-montserrat">Get In Touch</h2>
                                <p className="text-[18px] font-[400] text-[#000000] pb-4 md:pb-[45px]">Reach out for any inquiry</p>
                                <form
                                    onSubmit={(e) => e.preventDefault()}
                                    className="space-y-5"
                                >
                                    <div>
                                        <label className="font-[600] flex gap-1">
                                            <img src={user} alt="" />
                                            Full name
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                        />
                                    </div>
                                    <div>
                                        <label className="font-[600] flex gap-1">
                                            <img src={mail} alt="" />
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            required
                                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                        />
                                    </div>

                                    <div>
                                        <label className="font-[600] flex gap-1">
                                            <img src={mail} alt="" />
                                            Message
                                        </label>
                                        <textarea required className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"></textarea>
                                    </div>
                                    <button
                                        className="px-7 py-2 text-white font-medium bg-[#005AAB] rounded-lg duration-150"
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                            <div className="w-full">
                                <img className="h-full md:h-[626px]" src={Map} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center py-10">
                <div className="w-[93%]">
                    <h3 className="text-[#E1B524] text-[16px] md:text-[24px] font-semibold">
                        Gallery
                    </h3>
                    <p className="text-[25px] md:text-[51px] font-[400] text-[#000000] pb-4">Lorem Ispum Lorem Ispum</p>
                </div>
                <div className="flex flex-wrap md:-m-2 -m-1 w-[93%]">
                    <div className="flex flex-wrap w-full md:w-1/3">
                        <div className="md:p-2 p-1 w-full">
                            <img alt="gallery" className="w-full h-[246.49px] object-cover object-center block rounded-md" src={img1} />
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <img alt="gallery" className="w-full object-cover h-[246.49px] object-center block rounded-md" src={img2} />
                        </div>
                        <div className="md:p-2 p-1 w-1/2">
                            <img alt="gallery" className="w-full object-cover h-[246.49px] object-center block rounded-md" src={img3} />
                        </div>

                    </div>
                    <div className="flex flex-wrap w-full md:w-1/3">
                        <div className="md:p-2 p-1 w-full">
                            <img alt="gallery" className="w-full h-full md:h-[515.66px] object-cover object-center block rounded-md" src={img4} />
                        </div>

                    </div>
                    <div className="flex flex-col flex-wrap w-full md:w-1/3">

                        <div className="md:p-2 p-1 w-full">
                            <img alt="gallery" className="w-full object-cover h-[246.49px] object-center block rounded-md" src={img5} />
                        </div>
                        <div className="md:p-2 p-1 w-full">
                            <img alt="gallery" className="w-full object-cover h-[246.49px] object-center block rounded-md" src={img6} />
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={() => setIsOpen(true)}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 bg-[#0000006b]">
                        <DialogPanel transition className="w-full max-w-lg rounded-xl bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
                            <div className="flex justify-between border-b-[1px] border-[#e9e9e9] pb-3 mb-3">
                                <DialogTitle as="h3" className="text-[20px] font-medium text-black">Create Account</DialogTitle>
                                <Button type="button" className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700" onClick={() => setIsOpen(false)}>
                                    <IoIosCloseCircleOutline size={20} />
                                </Button>
                            </div>
                            <div className="my-4 mt-[4rem] flex flex-wrap justify-center gap-5">
                                <Button type="button" onClick={() => handleRedirection("venue")} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">As a Venue</Button>
                                <Button type="button" onClick={() => handleRedirection("host")} className="px-5 py-2 bg-[#005AAB] w-[47%] text-white text-center rounded-full shadow-md block text-[15px] text-nowrap font-[500]">As a Host</Button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <Footer />
        </>
    )
}
export default Landing2;