
// import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useNavigate } from 'react-router-dom';
import { setRedirectURL } from '../../constants';
import { useDispatch } from 'react-redux';
import HeroSection from './HeroSection';
import Footer from '../Footer';

export const LandingPage = () => {
    // const sessionContext = useSessionContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const onGetStarted = () => {
    //     setRedirectURL('/home');
    //     if (sessionContext.doesSessionExist === true) {
    //         navigate('/home');
    //     } else {
    //         navigate('/auth/?redirectToPath=%2Fhome');
    //     }
    // };

    return (
        <>
          <HeroSection />
       </>

    );
};
