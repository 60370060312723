
export const setRedirectURL = (url: string) => {
    localStorage.setItem('redirectURL', url);
};

export const getRedirectURL = presetURL => {
    if (presetURL) return presetURL;
    let url = localStorage.getItem('redirectURL');
    return url ? url : '/home';
};


export const getPrivacyPolicyURL = () => {
    return '';
};

export const getTermsOfServiceURL = () => {
    return '';
};

export const getAcceptableUsePolicyURL = () => {
    return '';
};

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};