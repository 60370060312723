import { NavigationBar } from '../Landing/NavigationBar';
import Footer from '../Footer';
import Img1 from '../../assets/images/filter-img1.png';
import Ticket from '../../assets/images/ticket.png';
import location from '../../assets/images/Icons/location.svg';
import CustomMap from './CustomMap';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import moment from 'moment';
import methodModel from '../../methods/methods';
import { IoMdClose } from 'react-icons/io';

const FilterPage = () => {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [filters, setFilters] = useState({ search: "", venueId: id || "" })
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState([])

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [])

    const getData = (f = {}) => {
        const filter = { ...filters, ...f }
        ApiClient.get(`event/list/venueId`, filter).then(res => {
            if (res.success) {
                setData(res?.data)
                let locations = []
                let mapview = {}
                res?.data?.map((item,index) => {
                    locations.push({ lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], icon: methodModel.noImg(item?.images?.[0]), info: item?.title })
                    if(index === 0){
                        mapview = { lat: item?.coordinates?.coordinates?.[1], lng: item?.coordinates?.coordinates?.[0], icon: methodModel.noImg(item?.images?.[0]), info: item?.title }
                    }
                })
                setLocations(locations)
                setMapView(mapview)
            }
        })
    }

    const searchInput = (value) => {
        if (value?.length === 0) {
            getData({ search: "" })
        }
        setFilters({ ...filters, search: value })
    }
    const clearSearch = () => {
        setFilters({ ...filters, search: "" })
        getData({ search: "" })
    }
    const handleSearch = (e) => {
        e.preventDefault()
        getData({ search: filters?.search })
    }

    return (
        <>
            <NavigationBar />
            <div className="bg-[#D9D9D938]">
                <div className="px-8 mx-auto flex flex-col md:flex-row">
                    <div className="md:max-w-[140px] w-full flex flex-col gap-[43px] py-12 border-r border-[#6F6F6F4D]">
                        <h2 className="font-semibold text-xl leading-[30px] text-left text-black font-montserrat">
                            Filters
                        </h2>

                        <div className="flex flex-col gap-[18px] pb-[30px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans mb-[12px]">
                                Price
                            </h3>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Free
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Pro
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-col gap-[18px] pb-[30px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans mb-[12px]">
                                Date
                            </h3>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Today
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Tomorrow
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    This Week
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    This Weekend
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Pick a Date
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <label
                                    htmlFor=""
                                    className="font-normal text-base leading-[24px] text-left text-[#4539B4] font-open-sans"
                                >
                                    More
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-col gap-[18px] pb-[30px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans mb-[12px]">
                                Category
                            </h3>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Adventure Travel
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Art Exhibitions
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Auctions & Fundraisers
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Beer Festivals
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Benefit Concerts
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#4539B4] font-open-sans"
                                >
                                    More
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-col gap-[18px] pb-[30px] border-b border-[#6F6F6F4D]">
                            <h3 className="font-semibold text-md leading-[28px] text-left text-[#2D2C3C] font-open-sans mb-[12px]">
                                Format
                            </h3>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Community Engagement
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Concerts & Performances
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Conferences
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Experiential Events
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="checkbox" placeholder="" />
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#2B293D] font-open-sans"
                                >
                                    Festivals & Fairs
                                </label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <label
                                    htmlFor=""
                                    className="font-normal text-xs leading-[24px] text-left text-[#4539B4] font-open-sans"
                                >
                                    More
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 py-12 flex flex-col px-4">
                        <form onSubmit={handleSearch} className='relative'>
                            <input type="text" value={filters?.search} onChange={e => searchInput(e.target.value)} placeholder="Search..." className="mb-4 p-2 w-full border border-[#6F6F6F4D] rounded-md font-normal text-base leading-[24px] text-left font-open-sans" />
                            {filters?.search?.length > 0 && <IoMdClose size={20} className='absolute top-3 right-3 cursor-pointer' onClick={e => clearSearch()} />}
                            <button type='submit' className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-2 px-5 text-white hover:text-[#005AAB] border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-full md:inline-flex">Search</button>
                        </form>
                        <div className="flex flex-col gap-8 mt-8">
                            {data && data?.map((item, index) => {
                                return <Link to={`/EventDetails/${item?._id || item?.id}`} key={index}>
                                    <div className="flex gap-[15px] items-center">
                                        <img className="max-w-[150px] w-full" src={methodModel.noImg(item?.images?.[0])} alt='event_image' />
                                        <div className="flex flex-col gap-[12px]">
                                            <h4 className="font-semibold text-[18px] leading-[23.4px] tracking-[0.03em] text-left font-open-sans text-black capitalize">{item?.title}</h4>
                                            <p className="font-normal text-xs leading-[18.2px] tracking-[0.03em] text-left font-open-sans text-black">{moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}</p>
                                            <p className="flex gap-2 items-center text-black font-normal text-xs leading-[18.2px] tracking-[0.03em] text-left font-open-sans">
                                                <img className="w-[20px] h-[20px]" src={location} alt="location_icon" />
                                                {item?.location}
                                            </p>
                                            <p className="flex gap-2 items-center text-black font-normal text-xs leading-[18.2px] tracking-[0.03em] text-left font-open-sans capitalize">
                                                <img className="w-[20px] h-[20px]" src={Ticket} alt="ticket_icon" />{item?.type === "paid" ? `$${item?.price_of_ticket}` : item?.type}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            })}
                            {data?.length === 0 &&
                                <div className='text-center'>No Records Found</div>
                            }
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-4">
                        <CustomMap locations={locations} mapView={mapView} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FilterPage;
