import { useState, useEffect } from 'react';
import { NavigationBar } from '../Landing/NavigationBar';
import { useNavigate, useSearchParams, Link, useParams } from 'react-router-dom';
import { emailPasswordSignIn } from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import { getRedirectURL, setRedirectURL } from '../../constants';
// import
import Footer from '../Footer';
import CustomMap from './CustomMap';
import InstaIcon from '../../assets/images/Icons/insta.png';
import FBIcon from '../../assets/images/Icons/face.png';
import LinkIcon from '../../assets/images/Icons/link.png';
import TwtIcon from '../../assets/images/Icons/twit.png';
import location from '../../assets/images/loc.png';
import calender from '../../assets/images/cal.png';
import EDImg from '../../assets/images/ed-img1.png';
import HeartIcon from '../../assets/images/heart.png';
import EDuration from '../../assets/images/e-duration.png';
import EAtten from '../../assets/images/e-atten.png';
import Ecall from '../../assets/images/e-call.png';
import EDImg1 from '../../assets/images/ed-img2.png';
import EDImg2 from '../../assets/images/ed-img2.png';
import EDImg3 from '../../assets/images/ed-img3.png';
import Ticket from '../../assets/images/ticket.png';
import Star from '../../assets/images/star.png';
import prevIcon from '../../assets/images/prevIcon.png';
import nextIcon from '../../assets/images/nextIcon.png';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css/navigation';
import 'swiper/css';
import Modal from '../Components/Modal';
import google from '../../assets/images/google.svg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/Instagram.svg';
import linkedin from '../../assets/images/linkedin.svg';
import lock from '../../assets/images/Icons/lock.svg';
import message from '../../assets/images/Icons/message.svg';
import Location from '../../assets/images/Icons/location.svg';
import eyeoff from '../../assets/images/Icons/eye-off.svg';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import moment from 'moment';
import loader from '../../methods/loader';

const EventDetails = ({ setIsSignup, thirdPartySignInClicked }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [detail, setDetail] = useState({})
    const [categoryEvents, setCategoryEvents] = useState([])
    const [params] = useSearchParams()

    // State variables for form fields
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [locations, setLocations] = useState([])
    const [mapView, setMapView] = useState({})

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        if (id) {
            getEventDetail()
        }
        if (params.get('redirectToPath')) {
            setRedirectURL(params.get('redirectToPath'));
        }
    }, [id])

    const getEventDetail = () => {
        loader(true)
        ApiClient.get(`event/detail?id=${id}`).then(res => {
            if (res.success) {
                const data = res?.payload
                setDetail(data)
                getCategoryEvents(data?.category?._id || data?.category?.id)
                setLocations([{ lat: data?.coordinates?.coordinates?.[1], lng: data?.coordinates?.coordinates?.[0], icon: methodModel.noImg(data?.images?.[0]), info: data?.title }])
                setMapView({ lat: data?.coordinates?.coordinates?.[1], lng: data?.coordinates?.coordinates?.[0], icon: methodModel.noImg(data?.images?.[0]), info: data?.title })
            }
            loader(false)
        })
    }

    const getCategoryEvents = (categoryId) => {
        if (categoryId) {
            ApiClient.get(`event/listing?category=${categoryId}`).then(res => {
                if (res.success) {
                    setCategoryEvents(res?.data?.filter((item)=>item?._id !== id))
                }
            })
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (email.length < 1) {
            alert('Please enter your email and password to login.');
            setIsLoading(false);

            return;
        }
        if (password.length < 6) {
            alert('Your password must be at least 6 characters');
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        try {
            let response = await emailPasswordSignIn({
                formFields: [
                    {
                        id: 'email',
                        value: email
                    },
                    {
                        id: 'password',
                        value: password
                    }
                ]
            });

            if (response.status === 'FIELD_ERROR') {
            } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
                window.alert('Email password combination is incorrect.');
                setIsLoading(false);
            } else {
                // sign in successful. The session tokens are automatically handled by
                // the frontend SDK.
                setIsLoading(true);
                navigate(getRedirectURL(params.get('redirectToPath')));
            }
        } catch (err) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                window.alert(err.message);
                setIsLoading(false);
            } else {
                window.alert(err);
                setIsLoading(false);
            }
        }
    }
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const calculateDuration = (key) => {
        // Convert the input strings to Date objects
        const startDate = new Date(detail?.eventStartDate);
        const endDate = new Date(detail?.eventEndDate);
        // Calculate the difference in milliseconds
        const diffInMs = endDate.getTime() - startDate.getTime();
        // Calculate the difference in days, hours, and minutes
        const days = Math.floor(diffInMs / (1000 * 3600 * 24));
        const hours = Math.floor((diffInMs % (1000 * 3600 * 24)) / (1000 * 3600));
        const minutes = Math.floor((diffInMs % (1000 * 3600)) / (1000 * 60));
        // Return the results as an object
        if (key === "days") return (days || 0)
        else if (key === "hours") return (hours || 0)
        else if (key === "minutes") return (minutes || 0)
        else return ""
    }

    return (
        <>
            <NavigationBar />
            <div className="event-detail bg-[#D9D9D938] py-[40px]">
                <div className="w-[85%] mx-auto flex gap-9">
                    {/* <div className="flex flex-col max-w-[290px] pi-[20px] gap-3">
                        <p class="font-normal text-base leading-[30px] text-left text-[#1B1B25] font-poppins">
                            Share
                        </p>
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={InstaIcon}
                            alt=""
                        />
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={FBIcon}
                            alt=""
                        />
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={LinkIcon}
                            alt=""
                        />
                        <img
                            className="w-[50px] h-[50px] cursor-pointer"
                            src={TwtIcon}
                            alt=""
                        />
                    </div> */}
                    <div className="flex-1">
                        <img src={methodModel.noImg(detail?.images?.[0])} className='w-full h-[450px] object-cover' alt="event_image" />
                        <div className="flex py-10 flex-col md:flex-row gap-5 justify-between">
                            <div className="">
                                <h2 className="font-semibold text-3xl leading-[36.57px] text-left text-black font-montserrat capitalize">
                                    {detail?.title}
                                </h2>
                                <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                                    <img className="mr-2 w-[18px] h-[18px]" src={location} alt="location_icon" />
                                    {detail?.location}
                                </p>
                                <p className="flex items-center font-semibold text-base leading-[23.4px] tracking-[0.03em] text-left text-[#1B1B25] font-open-sans">
                                    <img className="mr-2 w-[18px] h-[18px]" src={calender} alt="date_icon" />
                                    {moment(detail?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                </p>
                                <p className="w-full max-w-[570px] font-normal text-base leading-[27.54px] tracking-[0.03em] text-left text-black font-open-sans" dangerouslySetInnerHTML={{ __html: detail?.short_description }}></p>
                                <div className="flex gap-[20px] pt-[25px]">
                                    <a className="flex items-center justify-center text-[18px] text-nowrap font-semibold py-2 px-6 hover:text-black text-white border border-[#005AAB] bg-[#005AAB] hover:bg-transparent rounded-[16px] md:inline-flex">
                                        Follow
                                    </a>
                                    <a className={`flex items-center text-[18px] text-nowrap font-semibold hover:bg-[#005AAB] hover:text-white text-black border py-2 px-6 border-[#005AAB] rounded-[16px] md:inline-flex`}>
                                        <img className="mr-2 w-[18px] h-[18px] hover:filter hover:brightness-0 hover:saturate-100 hover:invert-100 hover:sepia-100 hover:hue-rotate-163 hover:brightness-109 hover:contrast-101" src={HeartIcon} alt="like_icon" />
                                        Like
                                    </a>
                                </div>
                            </div>
                            {detail?.isOnline &&
                                <div className="flex justify-end items-center">
                                    <div
                                        className="w-[285px] flex flex-col items-center border border-[black] px-[20px] py-[20px]"
                                        style={{
                                            boxShadow: '6px 8px 0px 0px #E1B524',
                                            borderRadius: '8px'
                                        }}
                                    >
                                        <p>Tickets starting at</p>
                                        <h3 className="font-montserrat text-lg font-semibold leading-6 text-left">
                                            ${parseFloat(detail?.price_of_ticket || 0).toFixed(2)}
                                        </h3>
                                        <button
                                            onClick={openModal}
                                            className="mt-5 w-full bg-[#005AAB] text-center font-poppins text-base font-semibold leading-7 text-left text-white py-[10px] px-[16px] gap-10 rounded-4"
                                        >
                                            Buy Tickets
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            <h2 className="font-montserrat text-xl font-semibold leading-7 text-left text-gray-900">
                                Event Information
                            </h2>
                            <div className="grid grid-cols-1 gap-8 mt-5 md:mt-10 md:grid-cols-3">
                                <div className="flex gap-[20px]">
                                    <img className="w-[45px] h-[45px]" src={EDuration} alt="duration_icon" />
                                    <div className="flex flex-col">
                                        <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                            Duration
                                        </p>
                                        {/* <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            20.00 - 21.56 WIB
                                        </span> */}
                                        <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            {calculateDuration("days") !== 0 ? `${calculateDuration("days")} days ` : null}
                                            {calculateDuration("hours") !== 0 || calculateDuration("minutes") !== 0 ? `${calculateDuration("hours")} hours ` : null}
                                            {calculateDuration("minutes") !== 0 ? `${calculateDuration("minutes")} minutes` : null}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex gap-[20px]">
                                    <img className="w-[45px] h-[45px]" src={EAtten} alt="audience_icon" />
                                    <div className="flex flex-col">
                                        <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                            Audience
                                        </p>
                                        <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            {detail?.audience || '--'}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex gap-[20px]">
                                    <img className="w-[45px] h-[45px]" src={Ecall} alt="attention_icon" />
                                    <div className="flex flex-col">
                                        <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">
                                            Attention
                                        </p>
                                        <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">
                                            {detail?.attention || '--'}
                                        </span>
                                    </div>
                                </div>
                                {detail?.host?.map((item, index) => {
                                    return <Link to="/hostProfile" className="flex gap-[20px]" key={index}>
                                        <img src={methodModel.noImg(item?.image)} className="w-[45px] h-[45px] rounded-full bg-gray-600" />
                                        <div className="flex flex-col">
                                            <p className="font-open-sans text-lg font-semibold leading-6 tracking-tight text-left text-gray-700">Host</p>
                                            <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">{item?.fullName || item?.firstName}</span>
                                            <span className="font-open-sans text-base font-normal leading-6 tracking-tight text-left text-gray-600">{item?.email}</span>
                                        </div>
                                    </Link>
                                })}
                            </div>
                            <div className="mt-10">
                                <h3 className="font-open-sans text-base font-semibold leading-6 tracking-tight text-left text-gray-900">Description</h3>
                                <p className="font-open-sans text-base font-normal leading-7 tracking-tight text-left text-black" dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                            </div>
                            <div className="w-full mx-auto mt-8">
                                <CustomMap mapView={mapView} locations={locations} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {categoryEvents?.length !== 0 &&
                <div className="w-[85%] mx-auto py-5 md:py-10">
                    <h2 className="font-montserrat text-2xl font-semibold leading-9 text-left text-[#2D2C3C] mb-2">
                        Other events you may like
                    </h2>
                    <div className="w-full">
                        <Swiper
                            className="mySwiper"
                            loop={true}
                            slidesPerView={1}
                            spaceBetween={10}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            }}
                            modules={[Navigation]}
                            breakpoints={{
                                1100: {
                                    slidesPerView: 3,
                                    spaceBetween: 60
                                }
                            }}
                        >
                            {categoryEvents && categoryEvents?.map((item, index) => {
                                return <SwiperSlide key={index}>
                                    <Link to={`/EventDetails/${item?._id || item?.id}`}>
                                        <div className="overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800 mb-2">
                                            <img className="object-cover w-full h-[200px]" src={methodModel.noImg(item?.images?.[0])} alt="Article" />
                                            <div className="p-4">
                                                <h2 className="font-[500] text-[18px] capitalize">
                                                    {item?.title}
                                                </h2>
                                                <p className="font-[400] text-[14px]">
                                                    {moment(item?.eventStartDate).format("DD MMM, YYYY hh:mm A")}
                                                </p>
                                                <p className="flex gap-2 font-[400] text-[14px]">
                                                    <img src={Location} alt="location_icon" />
                                                    <span className='ellipsis_class text-[#585858] leading-[17px]'> {item?.location}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            })}

                            {/* <SwiperSlide>
                            <div className="py-[23px] flex flex-col justify-center items-center gap-[25px]">
                                <img className="" src={EDImg2} alt="" />
                                <div className="flex gap-[10px] px-[10px]">
                                    <div className="flex flex-col items-center">
                                        <h3 className="font-montserrat text-[16.53px] font-semibold leading-[20.15px] text-center text-[#4539B4]">
                                            NOV
                                        </h3>
                                        <p className="font-montserrat text-[17.91px] font-bold leading-[21.83px] text-center text-[#2D2C3C] whitespace-nowrap">
                                            25-26
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-[6px]">
                                        <h3 className="font-open-sans text-[16.53px] font-semibold leading-[22.52px] text-left text-[#2D2C3C]">
                                            Lakeside Camping at Pawna
                                        </h3>
                                        <p className="font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                            Adventure Geek - Explore the
                                            Unexplored, Mumbai
                                        </p>
                                        <p className="font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                            8:30 AM - 7:30 PM
                                        </p>
                                        <div className="flex gap-[6px]">
                                            <p className="flex items-center font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                                <img
                                                    className="w-[16x] h-[16px] mr-2"
                                                    src={Ticket}
                                                    alt=""
                                                />
                                                Free
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-[23px] flex flex-col justify-center items-center gap-[25px]">
                                <img className="" src={EDImg3} alt="" />
                                <div className="flex gap-[10px] px-[10px]">
                                    <div className="flex flex-col items-center">
                                        <h3 className="font-montserrat text-[16.53px] font-semibold leading-[20.15px] text-center text-[#4539B4]">
                                            NOV
                                        </h3>
                                        <p className="font-montserrat text-[17.91px] font-bold leading-[21.83px] text-center text-[#2D2C3C] whitespace-nowrap">
                                            25-26
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-[6px]">
                                        <h3 className="font-open-sans text-[16.53px] font-semibold leading-[22.52px] text-left text-[#2D2C3C]">
                                            Lakeside Camping at Pawna
                                        </h3>
                                        <p className="font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                            Adventure Geek - Explore the
                                            Unexplored, Mumbai
                                        </p>
                                        <p className="font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                            8:30 AM - 7:30 PM
                                        </p>
                                        <div className="flex gap-[6px]">
                                            <p className="flex items-center font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                                <img
                                                    className="w-[16x] h-[16px] mr-2"
                                                    src={Ticket}
                                                    alt=""
                                                />
                                                Free
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="py-[23px] flex flex-col justify-center items-center gap-[25px]">
                                <img className="" src={EDImg3} alt="" />
                                <div className="flex gap-[10px] px-[10px]">
                                    <div className="flex flex-col items-center">
                                        <h3 className="font-montserrat text-[16.53px] font-semibold leading-[20.15px] text-center text-[#4539B4]">
                                            NOV
                                        </h3>
                                        <p className="font-montserrat text-[17.91px] font-bold leading-[21.83px] text-center text-[#2D2C3C] whitespace-nowrap">
                                            25-26
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-[6px]">
                                        <h3 className="font-open-sans text-[16.53px] font-semibold leading-[22.52px] text-left text-[#2D2C3C]">
                                            Lakeside Camping at Pawna
                                        </h3>
                                        <p className="font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                            Adventure Geek - Explore the
                                            Unexplored, Mumbai
                                        </p>
                                        <p className="font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                            8:30 AM - 7:30 PM
                                        </p>
                                        <div className="flex gap-[6px]">
                                            <p className="flex items-center font-open-sans text-[12.4px] font-semibold leading-[16.89px] text-left text-[#5A5A5A]">
                                                <img
                                                    className="w-[16x] h-[16px] mr-2"
                                                    src={Ticket}
                                                    alt=""
                                                />
                                                Free
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}

                            <div className="swiper-button-prev w-[40px] h-[40px] after:hidden">
                                <img
                                    className="w-full h-full"
                                    src={prevIcon}
                                    alt="Previous"
                                />
                            </div>
                            <div className="swiper-button-next w-[40px] h-[40px]  after:hidden">
                                <img
                                    className="w-full h-full"
                                    src={nextIcon}
                                    alt="Next"
                                />
                            </div>
                        </Swiper>
                    </div>
                </div>
            }
            <Footer />
            <Modal open={isModalOpen} onClose={closeModal} title="My Modal">
                <form
                    onSubmit={handleSubmit}
                    className="w-full py-[60px] rounded-[16px]"
                >
                    <h2 className="text-[#005AAB]   text-[25.42px] leading-31 font-[800] text-left w-full">
                        Sign In
                    </h2>
                    <div className="flex flex-col pt-[27px] w-full gap-3 md:gap-[21px]">
                        <div className="relative flex">
                            <input
                                className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE] focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]"
                                type="email"
                                onChange={e => setEmail(e.target.value)}
                                name="email"
                                id="email"
                                placeholder="Email address"
                            />
                            <img
                                className="absolute right-3 top-[18px]"
                                src={message}
                                alt=""
                            />
                        </div>
                        <div className="relative flex">
                            <input
                                className="border-0 border-b-2 py-[10px] px-3 w-full placeholder:text-[#AEAEAE]  focus:outline-none placeholder:text-[13.56px] placeholder:font-[500]"
                                type="password"
                                name="password"
                                onChange={e => setPassword(e.target.value)}
                                id="password"
                                placeholder="Password"
                            />
                            <img
                                className="absolute right-3 top-[18px]"
                                src={eyeoff}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-[10px]">
                        <img className="me-1" src={lock} alt="" />
                        <Link
                            to="/auth/forgot-password"
                            className="text-[11.86px] font-[400] text-[#6B6B6B]"
                        >
                            Forgot Password
                        </Link>
                    </div>
                    <div className="flex flex-col pt-[30px] md:pt-[81px] w-full gap-4 md:gap-[35px]">
                        <button
                            type="submit"
                            className="text-[21.18px] text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer"
                        >
                            Sign Up
                        </button>
                        <div className="flex justify-center items-center gap-5">
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            <span className="text-[14.4px] font-[500] text-[#005AAB]">
                                Or Sign Up As{' '}
                            </span>
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                        </div>
                        <Link
                            to="/Ticket"
                            className="text-[21.18px] text-center text-[#FFFFFF] font-[700] bg-[#005AAB] rounded-[25.42px] py-2 cursor-pointer"
                        >
                            Guset
                        </Link>
                        <div className="flex justify-center items-center gap-5">
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                            <span className="text-[14.4px] font-[500] text-[#005AAB]">
                                Or Sign Up With{' '}
                            </span>
                            <div className="w-[67.78px] h-[0.5px] bg-[#CDCDCD]"></div>
                        </div>

                        <div>
                            <div className="w-[40.67px] h-[40.67px] rounded-full flex justify-center items-center gap-2 md:gap-9 mx-auto border-[#005AAB]">
                                <img
                                    className="cursor-pointer"
                                    src={google}
                                    onClick={() =>
                                        thirdPartySignInClicked('google')
                                    }
                                    alt=""
                                />
                                <img
                                    className="cursor-pointer"
                                    src={facebook}
                                    onClick={() =>
                                        thirdPartySignInClicked('google')
                                    }
                                    alt=""
                                />
                                <img
                                    className="cursor-pointer"
                                    src={linkedin}
                                    onClick={() =>
                                        thirdPartySignInClicked('google')
                                    }
                                    alt=""
                                />
                                <img
                                    className="cursor-pointer"
                                    src={instagram}
                                    onClick={() =>
                                        thirdPartySignInClicked('google')
                                    }
                                    alt=""
                                />
                            </div>
                        </div>
                        {/* <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">Do not have an account ? <Link to="/UserSignUp" className="text-[#005AAB] font-[700] cursor-pointer">Sign Up</Link></p> */}
                        <p className="text-[14.4px] font-[400] text-center text-[#a1a1a1]">
                            Don't have an account yet?{' '}
                            <a
                                onClick={() => setIsSignup(true)}
                                className="cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500"
                            >
                                Sign Up
                            </a>
                        </p>
                    </div>
                </form>
            </Modal>
        </>
    );
};
export default EventDetails;
